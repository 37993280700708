import React from 'react';
import SpaceCreate from "./SpaceCreate.tsx";

const SpaceCreateContainer = () => {
  return (
    <div>
      <SpaceCreate />
    </div>
  );
};

export default SpaceCreateContainer;
