import React from "react";
import { expectTypeOf } from "vitest";

interface Props {
  percent: boolean;
  valueY: number;
}
const RedLine: React.FC<Props> = ({ percent, scale, valueY }) => {
  return (
    <rect
      x={percent ? "70" : "95"}
      y={scale.y(valueY)}
      style={{ width: percent ? 480 : 430, height: 2 }}
      fill="#341B1B"
    />
  );
};

export default RedLine;
