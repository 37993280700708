import Title from "components/HomePage/Circles/AccountCircle/CreateAccount/Title";
import FormContainer from "./FormContainer";
import type { Dispatch, SetStateAction } from "react";
import check_logo from "image/Account/Check.svg";

const CreatePayment = ({
  setCreatingPayment,
}: {
  setCreatingPayment: Dispatch<SetStateAction<boolean>>;
}) => {
  return (
    <>
      <Title
        header="Create payment"
        title={`Change the fields as you see fit and click 
          the create button`}
        image={check_logo}
        className="rounded-tr-3xl py-8"
      />
      <FormContainer setCreatingPayment={setCreatingPayment} />
    </>
  );
};

export default CreatePayment;
