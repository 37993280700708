import type { IFormInputs } from "./CreatePayment/FormContainer";

export const daysOfWeek = ["M", "Tu", "W", "Th", "F", "Sa", "Su"] as const;

// I use this function for creating requst body for the create & edit payment features
export const createSpendBody = ({
  account_pk,
  category_pk,
  title,
  amount,
  day,
}: IFormInputs) => {
  const data = {
    title,
    amount: Number.parseFloat(amount),
    account_pk: Number.parseFloat(account_pk),
    category_pk: Number.parseFloat(category_pk),
  };
  if (Number.parseFloat(day)) {
    data.day_of_month = Number.parseFloat(day);
  } else {
    data.day_of_week = daysOfWeek.indexOf(day) + 1;
  }
  return data;
};
