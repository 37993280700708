import type { StateCreator } from "zustand";
import type { Subsription } from "utils/types/response/ProfileRes";

class userRestrictionsClass {
  constructor(
    public readonly categories: 25 | 50 | 100,
    public readonly accounts: 3 | 6 | 12,
    public readonly periodicSpends: 5 | 10 | 20,
    public readonly goals: 5 | 10 | 20,
    public readonly DowtAdvices: 7 | 13 | 25,
    public readonly spaces: 0 | 5
  ) {}
}

interface State {
  subscription: Subsription;
  userRestrictions: userRestrictionsClass;
}

interface Actions {
  setSubscription: (subscription: Subsription) => void;
}

export interface SubsriptionSlice extends State, Actions {}

export const createSubscriptionSlice: StateCreator<SubsriptionSlice> = (
  set
) => ({
  subscription: "free",
  userRestrictions: new userRestrictionsClass(25, 3, 5, 5, 7, 0),
  setSubscription: (subscription) => {
    set({ subscription });
    switch (subscription) {
      case "standart":
        set({
          userRestrictions: new userRestrictionsClass(50, 6, 10, 10, 13, 0),
        });
        break;
      case "premium":
      case "business":
        set({
          userRestrictions: new userRestrictionsClass(100, 12, 20, 20, 25, 5),
        });
        break;
      case "free":
        set({
          userRestrictions: new userRestrictionsClass(25, 3, 5, 5, 7, 0),
        });
        break;
    }
  },
});
