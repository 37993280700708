import React from "react";

export default ({
  r,
  color,
  strokeWidth,
  angle,
  filter,
}: {
  r: number;
  color: string;
  strokeWidth: number;
  angle: number;
  filter?: string;
}) => {
  const firstY = 125 - r * Math.cos(0);
  const firstX = 300;
  const lastY = 125 - r * Math.cos(angle);
  const lastX = 300 + r * Math.sin(angle);

  return (
    <path
      d={`M${firstX},${firstY} A${r},${r} 0 ${
        angle < Math.PI ? 0 : 1
      },1 ${lastX},${lastY}`}
      fill="none"
      stroke={color}
      strokeWidth={strokeWidth}
      filter={filter}
    />
  );
};
