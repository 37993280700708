import React, { useContext, useState } from "react";
import { VictoryPie } from "victory";
import { StatisticsContext } from "components/StatisticsPage";
import type {
  IncomesType,
  CategoriesType,
} from "utils/types/response/StatisticsResponse";
import Icons from "./Icons";

const BorderCircle = ({
  r,
  strokeWidth,
}: {
  r: number;
  strokeWidth: number;
}) => (
  <circle
    r={r}
    cx="300"
    cy="125"
    fill="none"
    stroke="#646464"
    strokeWidth={strokeWidth}
    filter="url(#shadow)"
  />
);

export default () => {
  const Categories = useContext(StatisticsContext).data
    ?.Categories as CategoriesType;
  const { percent, period } =
    useContext(StatisticsContext) && useContext(StatisticsContext);

  const chartPeriod = (period +
    (percent ? "_Percent" : "")) as keyof IncomesType;

  const data = Categories[chartPeriod];

  const chartData = Object.entries(data).map(([label, y]) => ({
    label,
    y: Number.parseInt(y),
  }));
  // Idk if this is a good name
  const [visibleIcon, setVisibleIcon] = useState<null | string>(null);

  return (
    <svg viewBox="0 0 600 250">
      <rect
        x="0"
        y="0"
        width="600"
        height="250"
        rx="30"
        ry="30"
        fill="#101010"
      />
      <VictoryPie
        origin={{ x: 300, y: 125 }}
        colorScale={["#402f0e", "#2b220e"]}
        innerRadius={120}
        data={
          Object.keys(data).length !== 0 ? chartData : [{ label: "none", y: 1 }]
        }
        standalone={false}
        height={250}
        width={600}
        events={[
          {
            target: "data",
            eventHandlers: {
              onMouseEnter: (e, data) => {
                setVisibleIcon(data.datum.label);
              },
              onMouseLeave: (e) => {
                if (!(e.relatedTarget.id === "smallCategory")) {
                  setVisibleIcon(null);
                }
              },
            },
          },
        ]}
        style={{
          labels: { display: "none" },
          data: {
            fillOpacity: 0.9,
            stroke: "#101010",
            strokeWidth: 3,
          },
        }}
      />
      <defs>
        <filter id="shadow">
          <feDropShadow dx="2" dy="2" stdDeviation="10" floodColor="#FFA800" />
        </filter>
      </defs>
      <BorderCircle r={122} strokeWidth={2} />
      <BorderCircle r={74} strokeWidth={1} />
      {typeof data === "object" && (
        <Icons
          data={data}
          visibleIcon={visibleIcon}
          setVisibleIcon={setVisibleIcon}
        />
      )}
    </svg>
  );
};
