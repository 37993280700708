import Header from "./Header";
import cl from "./StatisticsPage.module.scss";
import { Route, Routes } from "react-router-dom";
import Expenses from "./Statistics/Expenses";
import Incomes from "./Statistics/Incomes";
import Statistics from "./Statistics";
import withLoader from "utils/hoc/withLoader";
import Categories from "./Statistics/Categories";
import Recurring from "./Statistics/Recurring";
import Savings from "./Statistics/Savings";
import Balance from "./Statistics/Balance";

const StatisticsPage = () => {
  return (
    <div className={cl.container}>
      <Header />
      <Routes>
        <Route path="/" element={<Statistics />} />
        <Route path="/expenses" element={<Expenses />} />
        <Route path="/incomes" element={<Incomes />} />
        <Route path="/categories" element={<Categories />} />
        <Route
          path="/recurring-payments"
          element={<Recurring fontSize="30px" height="auto" />}
        />
        <Route path="/savings" element={<Savings />} />
        <Route path="/balance" element={<Balance />} />
      </Routes>
    </div>
  );
};

export default withLoader(StatisticsPage);
