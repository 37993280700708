import type { Dispatch, SetStateAction, FC } from "react";
import { clsx } from "clsx";

type Period = "Month" | "Three_month" | "Week" | "Year";

type Props = {
  periodButtons: string[];
  setPeriod: Dispatch<SetStateAction<Period>>;
  period: Period;
};

const PeriodButtons: FC<Props> = ({ periodButtons, setPeriod, period }) => {
  return (
    <div className="bg-[#1e1400]">
      {/* Period buttons */}
      {periodButtons.map((el) => (
        <button
          type="button"
          onClick={() => {
            if (el === periodButtons[2]) {
              setPeriod("Three_month");
            } else {
              setPeriod(el);
            }
          }}
          key={el}
          className={clsx("px-2 sm:px-6 text-[#966608]", {
            "bg-orange lg:px-16 text-black":
              el === period ||
              (el === periodButtons[2] && period === "Three_month"),
          })}
        >
          {el}
        </button>
      ))}
    </div>
  );
};

export default PeriodButtons;
