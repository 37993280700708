import React, { useState, useEffect, useRef, useCallback } from "react";
import CryptoAndStocksList from "./CryptoAndStocksList";
import styles from "./CryptoAndStocks.module.scss";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import DOMPurify from "dompurify";

interface CryptoAndStocksProps {
  onClose: () => void;
}

const CryptoAndStocks: React.FC<CryptoAndStocksProps> = ({ onClose }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const cryptoRef = useRef<HTMLDivElement>(null);
  const resizeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const savedSize = localStorage.getItem('panelSize');
    if (savedSize && cryptoRef.current) {
      const { width, height } = JSON.parse(DOMPurify.sanitize(savedSize));
      cryptoRef.current.style.width = `${width}px`;
      cryptoRef.current.style.height = `${height}px`;
    }

    const handleMouseMove = (e: MouseEvent) => {
      if (resizeRef.current && cryptoRef.current) {
        const rect = cryptoRef.current.getBoundingClientRect();
        const aspectRatio = rect.width / rect.height;

        let newHeight = Math.max(750, Math.min(e.clientY - rect.top, 870));
        let newWidth = newHeight * aspectRatio;

        if (newWidth > 510) {
          newWidth = 510;
          newHeight = newWidth / aspectRatio;
        }

        cryptoRef.current.style.width = `${newWidth}px`;
        cryptoRef.current.style.height = `${newHeight}px`;

        // Сохраняем новые размеры в localStorage
        localStorage.setItem(
          "panelSize",
          JSON.stringify(sanitizeObject({ width: newWidth, height: newHeight }))
        );
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.body.style.userSelect = '';
    };

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      document.body.style.userSelect = 'none';

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    const currentResizeRef = resizeRef.current;

    if (currentResizeRef) {
      currentResizeRef.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (currentResizeRef) {
        currentResizeRef.removeEventListener("mousedown", handleMouseDown);
      }
      document.body.style.userSelect = '';
    };
  }, []);

  return (
    <div
      ref={cryptoRef}
      className={`${styles.container} min-w-[432px] min-h-[750px]`}
      style={{ maxHeight: '870px', maxWidth: '510px', overflow: 'hidden' }}
    >
      <div className={styles.titleAndBack}>
        <button onClick={onClose} className={styles.backButton}>
          ←
        </button>
        <h2 className={styles.title}>Crypto and Stocks Prices</h2>
      </div>

      <input
        type="text"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className={styles.searchInput}
      />

      <CryptoAndStocksList searchTerm={searchTerm} />

      <div
        ref={resizeRef}
        className="absolute bottom-0 right-0 w-4 h-4 bg-[#ffa800] cursor-nwse-resize"
        style={{
          borderTopLeftRadius: '25px',
        }}
      ></div>
    </div>
  );
};

export default CryptoAndStocks;