import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SpaceAPI } from "services/API.ts";
import cl from "./space_create.module.scss";
import ThreeUsersIcon from "image/spaces_p/ThreeUsersIcon.svg";
import CurrencyMenu from "components/LoginPage/ChooseCurrency/CurrencyMenu/CurrencyMenu";
import AutoComplete from "components/LoginPage/ChooseCurrency/AutoComplete";
import { currencies } from "utils/constants/currencies";
import { useForm } from "react-hook-form";

const CreateSpace = () => {
  const [title, setTitle] = useState("");
  const [, setCurrencyName] = useState("");
  const [currencyCode, setCurrencyCode] = useState("");
  const navigate = useNavigate();
  const { register, handleSubmit, setValue } = useForm();

  const onSubmit = async () => {
    try {
      await SpaceAPI.createSpace({ title, currency: currencyCode });
      navigate("/space");
    } catch (error) {
      console.error("Error creating space:", error);
    }
  };

  const handleCurrencyClick = (e) => {
    const selectedCurrency = e.target.dataset.text;
    setCurrencyName(selectedCurrency);
    setCurrencyCode(currencies[selectedCurrency]);
    setValue("currency", selectedCurrency, { shouldValidate: true });
  };

  return (
    <div className={cl.container}>
      <div className={cl.content}>
        <div className={cl.leftSide}>
          <div className={cl.title}>
            <img src={ThreeUsersIcon} alt="it's loading" />
            <div className="w-[50vw] max-w-[15rem]">
              <p className="mb-[14px]">Create Space</p>
              <p className="text-[15px] text-[#6E6E6E]">
                Define the title and select a currency for your new space.
              </p>
            </div>
          </div>
          <form className={cl.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={cl.formGroup}>
              <div className={cl.titleField}>
                <input
                  type="text"
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Title"
                  required
                />
              </div>
            </div>
            <div className={cl.formGroup}>
              <div className={cl.currencyInputContainer}>
                <AutoComplete
                  register={register}
                  placeholder="Currency"
                  values={Object.keys(currencies)}
                  onClickElement={handleCurrencyClick}
                />
              </div>
            </div>
            <div className={cl.buttons}>
              <button
                className="rounded-l-[12px] bg-[#181818]"
                type="button"
                onClick={() => navigate(-1)}
              >
                Back
              </button>
              <button
                className="rounded-r-[12px] bg-[#FFA800] font-bold text-[black] color-[#181818]"
                type="submit"
              >
                Create
              </button>
            </div>
          </form>
        </div>
        <CurrencyMenu onClick={handleCurrencyClick} />
      </div>
    </div>
  );
};

export default CreateSpace;
