import React from 'react';
import cl from './confirm_dialog.module.scss'; // Assuming you have a corresponding SCSS file for styling

interface ConfirmDialogProps {
  message: string;
  onConfirm: () => void;
  onCancel: () => void;
}

const ConfirmDialog: React.FC<ConfirmDialogProps> = ({ message, onConfirm, onCancel }) => {
  return (
    <div className={cl.dialogOverlay}>
      <div className={cl.dialog}>
        <p>{message}</p>
        <div className={cl.buttons}>
          <button className={cl.confirmButton} onClick={onConfirm}>Yes</button>
          <button className={cl.cancelButton} onClick={onCancel}>No</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDialog;
