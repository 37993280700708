import getSpaceId from "utils/constants/getSpaceId";
import { instance } from "./instances";
import type { AxiosResponse } from "axios";
import type { getBackupListRes } from "utils/types/response/MonthsRes";

export const MonthsAPI = {
  getBackupList: (data: {
    year: number;
    month: number;
  }): Promise<AxiosResponse<getBackupListRes>> =>
    instance.post(`/my_spaces/${getSpaceId()}/backups/`, data),
};
