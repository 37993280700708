import getSpaceId from "utils/constants/getSpaceId";
import { instance } from "./instances";
import { AxiosResponse } from "axios";
import { currencies } from "utils/constants/currencies";

interface GoalInterface {
  id: number;
  title: string;
  goal: string;
  goal_converted: string;
  collected: number;
  collected_converted: string;
  father_space: number;
  collected_percentage: string;
  currency: keyof typeof currencies;
  created_date: string;
  created_time: string;
}

export const GoalsAPI = {
  getGoals: (): Promise<AxiosResponse<GoalInterface[]>> =>
    instance.get(`/my_spaces/${getSpaceId()}/my_goals/`),
  createGoal: (data: { title: string; goal: number }) =>
    instance.post(`/my_spaces/${getSpaceId()}/create_goal/`, data),
  deleteGoal: (goalId: number) =>
    instance.delete(`/my_spaces/${getSpaceId()}/delete_goal/${goalId}/`),
  transferToGoal: (data: TransferToGoalReq) =>
    instance.put(`/my_spaces/${getSpaceId()}/transfer/`, {
      from_object: "account",
      to_object: "goal",
      ...data,
    }),
  editGoal: ({
    goalId,
    title,
    goal,
  }: {
    goalId: number;
    title: string;
    goal: number;
  }): Promise<AxiosResponse> =>
    instance.put(`/my_spaces/${getSpaceId()}/my_goals/${goalId}/`, {
      title,
      goal,
    }),
};
