import { sanitizeObject } from "./sanitizeObject";

const memoize = (fn) => {
  const cache = new Map();
  return (...args) => {
    const key = JSON.stringify(sanitizeObject(args));
    if (cache.has(key)) {
      return cache.get(key);
    }
    const result = fn(...args);
    cache.set(key, result);
    return result;
  };
};

export default memoize;
