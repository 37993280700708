import type { INotification } from "utils/types/response/NotificationsRes";
import Notification from "./Notification";
import type { MutableRefObject } from "react";
import Loader from "./Loader";

const NotificationsWindow = ({
  data,
  NotificationsRef,
  refForLastNotification,
  isLoading,
}: {
  data: INotification[] | undefined;
  NotificationsRef: MutableRefObject<null>;
  refForLastNotification: any;
  isLoading: boolean;
}) => {
  return (
    <aside
      ref={NotificationsRef}
      style={
        // If the user uses a browser other than firefox,
        // then I change the scroll style (in firefox, the scrollbar is cool by default)
        !navigator.userAgent.toLowerCase().includes("firefox")
          ? {
              scrollbarColor: "#909296 #31363b",
              scrollbarWidth: "thin",
            }
          : undefined
      }
      className="top-6 overflow-y-auto overflow-x-hidden p-5 right-6 bg-[#101010] w-[90vw] z-10 rounded-3xl h-[90dvh] max-w-[500px] text-[40px] text-white"
    >
      Notification center
      {isLoading ? (
        <Loader />
      ) : data?.length === 0 ? (
        <p>You don't have any notifications</p>
      ) : (
        data?.map((el, i) => (
          <Notification
            key={`${el.id} ${el.type}`}
            importance={el.importance}
            time={el.time}
            date={el.date}
            message={el.message}
            type={el.type}
            refForLastNotification={
              i + 1 === data.length ? refForLastNotification : undefined
            }
          />
        ))
      )}
    </aside>
  );
};

export default NotificationsWindow;
