import React, { useContext } from "react";
import { StatisticsContext } from "components/StatisticsPage";
import {
  VictoryAxis,
  VictoryChart,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import { useDataToChart } from "./useDataToChart";
import currencyToSymbolMap from "currency-symbol-map";
import type { BalanceType } from "utils/types/response/StatisticsResponse";
import RedLine from "./RedLine";
import formatLargeNumber from "utils/functions/formatLargeNumber";

export default () => {
  const Balance = useContext(StatisticsContext).data?.Balance as BalanceType;
  const { percent, period, currency } =
    useContext(StatisticsContext) && useContext(StatisticsContext);

  const data = Object.keys(Balance).length ? Balance[period].balance : {};
  const currencySymbol = currencyToSymbolMap(currency as string) as string;
  const chartData = useDataToChart(data, period, percent, currencySymbol);
  const maxValue = Math.max(...chartData.map((el) => el.y));
  const isMobile =
    window.ReactNativeWebView ||
    /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(
      navigator.userAgent,
    );
  return (
    <VictoryChart
      width={600}
      height={250}
      containerComponent={
        <VictoryVoronoiContainer style={{ touchAction: "auto" }} />
      }
      domainPadding={{ x: 25 }}
      padding={{ left: 70, right: 50, bottom: 50, top: 30 }}
      domain={{
        y: maxValue ? [0, maxValue] : [0, 100],
      }}
    >
      {/* The bg */}
      <rect rx="30" ry="30" fill="#101010" />
      {/* The central red line*/}
      <RedLine percent={percent} valueY={chartData[0].y} />
      {/* The chart */}
      <VictoryLine
        labelComponent={
          <VictoryTooltip active={isMobile} flyoutStyle={{ fill: "#161616" }} />
        }
        style={{
          data: {
            stroke: "#FFA800",
            filter: "drop-shadow(0px 0px 5px rgba(255, 168, 0, 0.5))",
          },
          labels: {
            fill: "#8F8B8B",
          },
        }}
        data={chartData}
      />
      {/* The bottom's axis */}
      <VictoryAxis
        style={{
          grid: { stroke: "grey" },
          axis: { strokeWidth: 5, stroke: "gray" },
          tickLabels: {
            fontSize: 10,
            fontWeight: "bold",
            fill: "#646464",
          },
        }}
        offsetY={50}
      />
      {/* The vertical axis */}
      <VictoryAxis
        dependentAxis
        style={{
          axis: { display: "none" },
          tickLabels: { fontWeight: "bold", fill: "#646464" },
          axisLabel: {
            fontWeight: "bold",
            fill: "#646464",
          },
        }}
        tickFormat={(tick: number) => {
          const formattedTick = formatLargeNumber(tick);
          return percent ? `%${formattedTick}` : currencySymbol + formattedTick;
        }}
        axisValue={chartData[0].x}
      />
    </VictoryChart>
  );
};
