import {
  type Dispatch,
  type SetStateAction,
  useState,
  type ReactNode,
} from "react";
import cl from "./Form.module.scss";
import type { IFormInputs } from "./CreateAccount";
import type {
  SubmitHandler,
  UseFormRegister,
  UseFormHandleSubmit,
} from "react-hook-form";
import withErrorShow from "utils/hoc/withErrorShow";

const checkMark = (color: "#FFA800" | "#6E6E6E") => (
  <svg
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ width: "30px" }}
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <path
      id="Vector"
      d="M20 10C20 15.52 15.52 20 10 20C4.47 20 0 15.52 0 10C0 4.47 4.47 0 10 0C15.52 0 20 4.47 20 10ZM14.03 6.96C14.32 7.26 14.32 7.73 14.03 8.03L9.03 13.03C8.73 13.32 8.26 13.32 7.97 13.03L5.97 11.03C5.67 10.73 5.67 10.26 5.97 9.96C6.26 9.67 6.73 9.67 7.03 9.96L8.5 11.43L10.73 9.2L12.97 6.96C13.26 6.67 13.73 6.67 14.03 6.96Z"
      fill={color}
      fillOpacity="1.000000"
      fillRule="evenodd"
    />
  </svg>
);

interface Props {
  register: UseFormRegister<IFormInputs>;
  setModal: Dispatch<SetStateAction<boolean>>;
  onSubmit: SubmitHandler<IFormInputs>;
  handleSubmit: UseFormHandleSubmit<IFormInputs>;
  submitText: string;
  AdditionalButton?: ReactNode;
}

const CreateAccountForm: React.FC<Props> = ({
  register,
  setModal,
  onSubmit,
  handleSubmit,
  submitText,
  AdditionalButton,
}) => {
  const [includeTotalBal, setIncludeTotalBal] = useState<boolean>(true);

  return (
    <form
      className={cl.form}
      style={{
        gridTemplateRows: `48px 48px 48px 48px  ${
          AdditionalButton ? "48px 30px" : "124px"
        }`,
      }}
      onSubmit={handleSubmit(onSubmit)}
    >
      <input
        {...register("title")}
        type="string"
        className={`${cl.field} bg-title_logo`}
        placeholder="Title"
      />
      <input
        {...register("currency")}
        placeholder="Currency"
        type="text"
        className={`${cl.field} bg-cash_logo`}
      />
      <input
        type="number"
        step="0.01"
        {...register("balance")}
        className={`${cl.field} !col-[1/3] !rounded-r-none bg-balance_logo`}
        placeholder="Balance"
      />
      <p className="bg-[#242424] text-center py-[13px] rounded-r-xl text-[20px] text-[#6E6E6E]">
        Optionally
      </p>
      <input
        type="checkbox"
        {...register("includeTotalBal")}
        id="includeTotalBal"
        className="hidden"
      />
      <label
        htmlFor="includeTotalBal"
        id="confLicense"
        onClick={() => setIncludeTotalBal((prev) => !prev)}
        className="rounded-full row-[4/5] justify-self-center"
      >
        {checkMark(includeTotalBal ? "#FFA800" : "#6E6E6E")}
      </label>
      <p className="col-[2/4] text-[20px] text-[#6E6E6E]">
        Include in the total balance
      </p>
      <div
        className={`flex col-[1/4] row-[5/6] text-[20px] ${
          AdditionalButton ? "mt-4" : "mb-[40px]"
        }`}
      >
        <button
          onClick={() => setModal(false)}
          type="button"
          className="w-[50%] font-normal rounded-l-xl text-white bg-[#181818] py-[12px]"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="w-[50%] bg-[#FFA800] rounded-r-xl py-[12px]"
        >
          {submitText}
        </button>
      </div>
      {AdditionalButton}
    </form>
  );
};

export default withErrorShow(CreateAccountForm);
