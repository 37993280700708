import React, {
  Dispatch,
  MutableRefObject,
  ReactNode,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import lightning from "image/Shop/lightning.svg";
import star from "image/Shop/star.svg";
import cl from "./ShopWindow.module.scss";
import Subsction from "./Subscription";
import type { UseFormSetValue, UseFormWatch } from "react-hook-form";
import type { IFormProps } from "utils/types/formProps";
import type { IFormInputs } from "./ShopWindowContainer";
import withErrorShow from "utils/hoc/withErrorShow";
import Modal from "utils/components/Modal";
import StripeWrapper from "./Stripe/StripeWrapper";
import Stripe from "./Stripe";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import DOMPurify from "dompurify";

interface Props extends IFormProps<IFormInputs> {
  featureRef: MutableRefObject<null>;
  standard_price: string;
  standardPeriod: string;
  standardAction: string;
  standardDetails: string | ReactNode;
  setValue: UseFormSetValue<IFormInputs>;
  premium_price: string;
  premiumPeriod: string;
  premiumAction: string;
  premiumDetails: string | ReactNode;
  watch: UseFormWatch<IFormInputs>;
  subscription: undefined | "standard" | "premium";
  isBuying: boolean;
  setIsBuying: Dispatch<SetStateAction<boolean>>;
  paymentData: {
    paymentIntent: string;
    ephemeralKey: string;
    customer: string;
    publishableKey: string;
  };
  isPending: boolean;
  onBack: () => void;
}

const ShopWindow: React.FC<Props> = ({
  featureRef,
  handleSubmit,
  onSubmit,
  standard_price,
  standardPeriod,
  standardAction,
  standardDetails,
  setValue,
  premium_price,
  premiumPeriod,
  premiumAction,
  premiumDetails,
  watch,
  subscription,
  register,
  isBuying,
  setIsBuying,
  paymentData,
  isPending,
  onBack,
}) => {
  const choosedSubscription = watch("subscription");
  const resizeRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const [windowSize, setWindowSize] = useState(() => {
    const savedSize = localStorage.getItem("panelSize");
    if (savedSize) {
      const { width, height } = JSON.parse(DOMPurify.sanitize(savedSize));
      return { width, height };
    }
    return { width: 432, height: 750 };
  });

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        setScrollPosition(contentRef.current.scrollTop);
      }
    };

    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (resizeRef.current && featureRef.current) {
        const rect = featureRef.current.getBoundingClientRect();
        const aspectRatio = rect.width / rect.height;

        let newHeight = Math.max(750, Math.min(e.clientY - rect.top, 870));
        let newWidth = newHeight * aspectRatio;

        if (newWidth > 510) {
          newWidth = 510;
          newHeight = newWidth / aspectRatio;
        }

        setWindowSize({ width: newWidth, height: newHeight });
        localStorage.setItem(
          "panelSize",
          JSON.stringify(
            sanitizeObject({ width: newWidth, height: newHeight }),
          ),
        );
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.body.style.userSelect = "";
    };

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      document.body.style.userSelect = "none";

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    if (resizeRef.current) {
      resizeRef.current.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (resizeRef.current) {
        resizeRef.current.removeEventListener("mousedown", handleMouseDown);
      }
      document.body.style.userSelect = "";
    };
  }, [featureRef]);

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        ref={featureRef}
        className={`${cl.container} min-w-[432px] min-h-[750px]`}
        style={{
          width: `${windowSize.width}px`,
          height: `${windowSize.height}px`,
          maxHeight: "870px",
          maxWidth: "510px",
          overflow: "hidden",
        }}
      >
        <div
          ref={contentRef}
          className="relative justify-items-center grid grid-cols-1 h-full overflow-auto"
        >
          <div className="relative flex items-center justify-center mb-4 w-full">
            <button
              onClick={onBack}
              type="button"
              className="absolute left-0 text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
            >
              ←
            </button>
            <h2 className="text-[20px] text-center w-full">Store</h2>
          </div>
          <Subsction
            image={
              <img
                src={lightning}
                alt="standard"
                className="bg-orange p-12 w-full rounded-t-xl"
              />
            }
            desription="More options for financial management"
            title="Standard"
            cost={standard_price}
            costBg="#3e2901"
            costTextColor="#c29131"
            buttonBg="#fea800"
            titleColor="#ffaa00"
            buttonTextColor="black"
            period={standardPeriod}
            action={standardAction}
            containerClass="row-[2/3] col-[1/4] w-[85%] mb-3"
            details={standardDetails}
            handleButtonClick={() => setValue("subscription", "standard")}
          />
          <Subsction
            image={
              <img
                src={star}
                alt="premium"
                className="bg-[#00c2ff] px-14 py-[50px] w-full rounded-t-xl"
              />
            }
            cost={premium_price}
            costBg="#003445"
            title="Premium"
            titleColor="#00c2ff"
            desription="Complete freedom for financial management"
            costTextColor="#2aa1ca"
            buttonBg="#00c2ff"
            buttonTextColor="white"
            period={premiumPeriod}
            action={premiumAction}
            containerClass="row-[3/4] col-[1/4] w-[85%]"
            details={premiumDetails}
            handleButtonClick={() => setValue("subscription", "premium")}
          />
          <div className="row-[5/6] col-[1/4] flex items-center w-[85%] mt-6">
            <input
              type="checkbox"
              id="terms of purchase"
              className="hidden"
              {...register("agreement")}
            />
            <label
              className={`duration-300 border text-center select-none rounded-2xl py-3 px-4 text-xl bg-[#181818] mr-4 w-[40%] ${
                watch("agreement")
                  ? "text-orange border-orange"
                  : "text-[#7f7f7f] border-[#3e3e3e]"
              }`}
              htmlFor="terms of purchase"
            >
              Okay
            </label>
            <p className="text-[#7f7f7f] text-[12px]">
              I agree to{" "}
              <a
                className="!underline cursor-pointer text-[#7f7f7f]"
                href="http://localhost:5173/terms-of-purchase/"
              >
                the terms of purchase of this subscription{" "}
              </a>
            </p>
          </div>
          <button
            className={`col-[1/4] mt-3 disabled:cursor-not-allowed w-[85%] duration-300 row-[6/7] py-4 rounded-2xl text-xl ${
              subscription === "standard"
                ? "bg-[#3e2a01] text-[#cc8d11]"
                : subscription === "premium"
                  ? "bg-[#003445] text-[#16a3d2]"
                  : "bg-[#181818] text-[#6a6a6a]"
            }`}
            type="submit"
            disabled={!choosedSubscription}
          >
            {!choosedSubscription
              ? "Please choose subscription"
              : `Buy ${choosedSubscription}`}
          </button>
        </div>
        <div
          ref={resizeRef}
          className="absolute bottom-0 right-0 w-4 h-4 bg-[#ffa800] cursor-nwse-resize"
          style={{
            borderTopLeftRadius: "25px",
          }}
        >
          .
        </div>
      </form>
      {isBuying && !isPending && (
        <Modal setActive={setIsBuying}>
          <StripeWrapper
            clientSecret={paymentData.paymentIntent}
            customer={paymentData.customer}
            ephemeralKey={paymentData.paymentIntent}
          >
            <Stripe />
          </StripeWrapper>
        </Modal>
      )}
    </>
  );
};

export default withErrorShow(ShopWindow);

