import Arc from "./Arc";

export default ({
  number,
  dotunRadius,
  dotunStrokeWidth,
}: { number: number; dotunRadius: number; dotunStrokeWidth: number }) => {
  return number === 0 ? (
    <Arc
      r={dotunRadius - dotunStrokeWidth / 2}
      color="#161616"
      strokeWidth={dotunStrokeWidth}
      angle={(3 * Math.PI) / 2}
    />
  ) : (
    new Array(number).fill(0).map((_, i) => {
      return (
        <Arc
          key={i}
          strokeWidth={dotunStrokeWidth / number}
          r={
            number === 1
              ? dotunRadius
              : dotunRadius + 12 - 2 * i - (i * dotunStrokeWidth) / number
          }
          // r={dotunRadius - i - (i * dotunStrokeWidth) / values.length}
          color="#161616"
          angle={(3 * Math.PI) / 2}
        />
      );
    })
  );
};
