import { StatisticsAPI } from "services/API/Statistics";
import { useQuery } from "@tanstack/react-query";
import StatisticsPage from "./StatisticsPage";
import {
  type Dispatch,
  type SetStateAction,
  createContext,
  useState,
} from "react";
import type { StatisticsRes } from "utils/types/response/StatisticsResponse";
import { useNavigate, useSearchParams } from "react-router-dom";

type Period = "Month" | "Three_month" | "Week" | "Year";

export const StatisticsContext = createContext<{
  data: StatisticsRes | undefined;
  period: Period;
  percent: boolean;
  setPercent: Dispatch<SetStateAction<boolean>>;
  setPeriod: Dispatch<SetStateAction<Period>>;
  currency: string | undefined;
}>({});

const getSpaceIdFromUrl = () => {
  const [searchParams] = useSearchParams();
  const spaceId: string | undefined | number = encodeURIComponent(
    searchParams.get("spaceId")
  );
  return Number.parseInt(spaceId);
};

export default () => {
  window.ReactNativeWebView?.postMessage("Here");
  const navigate = useNavigate();
  if (
    encodeURIComponent(window.location.pathname).includes("mobileStatistics") &&
    !window.ReactNativeWebView
  ) {
    navigate("/statistics");
  }
  const spaceIdFromUrl = getSpaceIdFromUrl();
  const { data, isPending } = useQuery({
    queryKey: ["get statistics"],
    queryFn: () =>
      // if spaceId in the url is undefined then I use spaceId from localStorage in the API
      StatisticsAPI.get(spaceIdFromUrl).then((res) => res.data),
  });

  const currency: string | undefined = data?.Expenses.Year
    ? typeof data.Expenses.Year === "object"
      ? Object.values(data.Expenses.Year)[0].Loss.match(/[a-zA-Z]+$/)[0]
      : data.Expenses.Year.match(/[a-zA-Z]+$/)[0]
    : undefined;

  const [period, setPeriod] = useState<Period>("Month");
  const [percent, setPercent] = useState<boolean>(true);

  return (
    <StatisticsContext.Provider
      value={{ data, period, setPeriod, percent, setPercent, currency }}
    >
      <StatisticsPage isLoading={isPending} />
    </StatisticsContext.Provider>
  );
};
