import React from 'react';
import MemberEdit from "./MemberEdit";

const MemberEditContainer = () => {
  return (
    <div>
      <MemberEdit />
    </div>
  );
};

export default MemberEditContainer;
