import { addDays, format, subMonths } from "date-fns";
import { useMemo } from "react";

const calculatePeriods = (
  start: Date,
  data: Record<string, string>,
  percent: boolean,
  currency: string,
) => {
  // Get an array of dates from the data object
  const dates = Object.keys(data);
  const today = new Date();
  const periodLengthInDays = Math.ceil(
    (today.getTime() - start.getTime()) / (1000 * 60 * 60 * 24),
  );

  const partLengthInDays = Math.floor(periodLengthInDays / 4);
  const periods: { x: string; y: number; label: string; money?: number }[] = [];
  // onHundredIndex - the index of the element relative
  // to which the percentages will be calculated is stored
  let oneHundredIndex = undefined;
  for (let i = 0; i < 4; i++) {
    // Define the start of the period
    const periodStartDate = addDays(start, i * partLengthInDays);

    // Define the end of the period
    let periodEndDate;
    if (i === 3) {
      periodEndDate = today; // The last period ends on the end date
    } else {
      periodEndDate = addDays(periodStartDate, partLengthInDays - 1);
    }

    // Form the date range of the period
    const periodRange = `${format(periodStartDate, "d MMM")} - ${format(
      periodEndDate,
      "d MMM",
    )}`;

    // Select the dates that fall within the current period
    const periodDates = dates.filter((date) => {
      const thisDate = new Date(new Date(date).setHours(0, 0, 0, 0));
      return thisDate >= periodStartDate && thisDate <= periodEndDate;
    });
    const balances = periodDates.map((date) => Number.parseFloat(data[date]));
    const value: number = balances.length
      ? balances[balances.length - 1]
      : periods[i - 1]
        ? periods[i - 1].y
        : 0;
    if (percent) {
      if (oneHundredIndex === undefined) {
        const percentValue =
          !balances.length || (balances.length === 1 && balances[0] === 0)
            ? 0
            : 100;
        periods.push({
          x: periodRange,
          y: percentValue,
          label: `%${percentValue}`,
          money: percentValue ? value : undefined,
        });

        oneHundredIndex = periods.findIndex((el) => el.money !== undefined);
        if (oneHundredIndex === -1) {
          oneHundredIndex = undefined;
        }
      } else {
        const divider = periods[oneHundredIndex].money || 1;
        const percentValue = balances.length
          ? Number.parseFloat(((value / divider) * 100).toFixed(2))
          : value;
        periods.push({
          x: periodRange,
          y: percentValue,
          label: `%${percentValue}`,
        });
      }
    } else {
      periods.push({
        x: periodRange,
        y: value,
        label: currency + value,
      });
    }
  }
  return periods;
};

const toChartWeek = (
  data: { [data: string]: string },
  percent: boolean,
  currency: string,
) => {
  const result = [];
  const today = new Date();
  // onHundredIndex - the index of the element relative
  // to which the percentages will be calculated is stored
  let oneHundredIndex = undefined;
  for (let i = 6; i >= 0; i--) {
    const d = new Date();
    d.setDate(today.getDate() - i);
    const formattedDate = d.toISOString().split("T")[0];
    const dayOfWeek = d.toLocaleString("en-US", { weekday: "short" });
    const value = data[formattedDate]
      ? Number.parseInt(data[formattedDate])
      : i === 6
        ? 0
        : result[result.length - 1].y;

    if (percent) {
      if (oneHundredIndex === undefined) {
        const percentValue = data[formattedDate] ? 100 : 0;

        result.push({
          x: dayOfWeek,
          y: percentValue,
          label: `%${percentValue}`,
          money: percentValue ? value : undefined,
        });
        oneHundredIndex = result.findIndex((el) => el.money !== undefined);
        if (oneHundredIndex === -1) {
          oneHundredIndex = undefined;
        }
      } else {
        const divider = result[oneHundredIndex].money || 1;
        const percentValue = data[formattedDate]
          ? Number.parseFloat(((value / divider) * 100).toFixed(2))
          : value;
        result.push({
          x: dayOfWeek,
          y: percentValue,
          label: `%${percentValue}`,
        });
      }
    } else {
      result.push({
        x: dayOfWeek,
        y: value,
        label: currency + value,
      });
    }
  }
  return result;
};

export const useDataToChart = (
  data: Record<string, string>,
  period: "Week" | "Month" | "Three_month" | "Year",
  percent: boolean,
  currency: string,
) =>
  useMemo(() => {
    if (period === "Week") {
      return toChartWeek(data, percent, currency);
    }
    const periodStart = subMonths(
      new Date().setHours(0, 0, 0, 0),
      period === "Three_month" ? 3 : period === "Month" ? 1 : 12,
    );

    return calculatePeriods(periodStart, data, percent, currency);
  }, [data, period, percent, currency]);
