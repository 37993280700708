import type { OneCategory } from "utils/types/response/CategoriesRes";
import type { StateCreator } from "zustand";

interface State {
  categories: OneCategory[] | undefined;
  categoryChanged: boolean; // Добавляем флаг для отслеживания изменений категорий
}

interface Actions {
  setCategories: (categories: State["categories"]) => void;
  setCategoryChanged: (status: boolean) => void; // Добавляем действие для изменения флага
}

export interface CategoriesSlice extends State, Actions {}

export const createCategoriesSlice: StateCreator<CategoriesSlice> = (set) => ({
  categories: undefined,
  categoryChanged: false, // Инициализируем флаг как false
  setCategories: (categories) => set(() => ({ categories, categoryChanged: false })), // Сбрасываем флаг после установки категорий
  setCategoryChanged: (status) => set(() => ({ categoryChanged: status })), // Обновляем только флаг
});
