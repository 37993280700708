import Title from "components/HomePage/Circles/AccountCircle/CreateAccount/Title";
import checklist_logo from "image/Goals/сhecklist.svg";
import EditSpendFormContainer from "./EditSpendFormContainer";
import type { Dispatch, SetStateAction } from "react";

const EditSpend = ({
  setEditingSpend,
  spendTitle,
  spendAmount,
  spendId,
  accountName,
  categoryName,
}: {
  spendTitle: string;
  spendAmount: string;
  spendId: number;
  setEditingSpend: Dispatch<SetStateAction<boolean>>;
  accountName: string;
  categoryName: string;
}) => {
  return (
    <>
      <Title
        header="Edit payment"
        title={`Change the fields as you see
        fit and click the edit button`}
        image={checklist_logo}
        className="rounded-tr-3xl py-8"
      />
      <EditSpendFormContainer
        spendId={spendId}
        spendAmount={spendAmount}
        spendTitle={spendTitle}
        setEditingSpend={setEditingSpend}
        accountName={accountName}
        categoryName={categoryName}
      />
    </>
  );
};

export default EditSpend;
