import type {
  ITotalBalance,
  OneAccount,
} from "utils/types/response/AccountRes";
import type { StateCreator } from "zustand";

interface State {
  accounts: OneAccount[] | undefined;
  totalBalance: ITotalBalance[] | undefined;
}

interface Actions {
  setAccounts: (accounts: State["accounts"]) => void;
  setTotalBalance: (accounts: State["totalBalance"]) => void;
}

export interface AccountSlice extends State, Actions {}

export const createAccountSlice: StateCreator<AccountSlice> = (set) => ({
  accounts: undefined,
  setAccounts: (accounts) => set(() => ({ accounts })),
  totalBalance: undefined,
  setTotalBalance: (totalBalance) => set(() => ({ totalBalance })),
});
