import { useEffect, useState, createContext, useContext } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AuthAPI } from "services/API/Auth";
import useStore from "store/store";
import App from "App.tsx";
import { useNavigate } from "react-router-dom";
import { ProfileAPI } from "services/API/Profile";
import { Subsription } from "utils/types/response/ProfileRes";
import Modal from "utils/components/Modal";
import CustomAlert from "utils/components/CustomAlert";

export const CustomAlertContext = createContext((text: string) => {});

const AppContainer = () => {
  const { loggedIn, setLoggedIn, setSubscription } = useStore();

  const checkAuth = useMutation({
    mutationKey: ["checkAuth"],
    mutationFn: () => AuthAPI.checkAuth(),
    onSuccess: () => setLoggedIn(true),
  });

  const navigate = useNavigate();

  useEffect(() => {
    const syncLogout = (e: StorageEvent) => {
      if (e.key === "logout") {
        navigate("/login");
      }
    };
    window.addEventListener("storage", syncLogout);
    if (localStorage.getItem("token")) {
      checkAuth.mutateAsync();
    }
  }, []);
  // getting user subscribe
  const { data: userRoles } = useQuery({
    queryKey: ["get user roles"],
    queryFn: () => ProfileAPI.ProfileView().then((res) => res.data.roles),
  });

  useEffect(() => {
    const userSubscription = userRoles?.find(
      (value) =>
        value === "free" ||
        value === "standart" ||
        value === "premium" ||
        value === "business"
    ) as Subsription;
    setSubscription(userSubscription);
  }, [userRoles]);
  const [showingCustomAlert, setShowingCustomAlert] = useState(false);
  const [customAlertText, setCustomAlertText] = useState("");
  const showCustomAlert = (text: string) => {
    setCustomAlertText(text);
    setShowingCustomAlert(true);
  };

  return (
    <>
      <CustomAlertContext.Provider value={showCustomAlert}>
        <App isLoading={checkAuth.isPending} loggedIn={loggedIn} />
        {showingCustomAlert && (
          <Modal setActive={setShowingCustomAlert}>
            <CustomAlert
              text={customAlertText}
              setActive={setShowingCustomAlert}
            />
          </Modal>
        )}
      </CustomAlertContext.Provider>
    </>
  );
};

export default AppContainer;
