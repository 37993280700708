export default ({
  x,
  y,
  icon,
  setVisibleIcon,
}: {
  x: number;
  y: number;
  icon: string;
  setVisibleIcon: React.Dispatch<React.SetStateAction<null | string>>;
}) => (
  <g onMouseLeave={() => setVisibleIcon(null)}>
    <rect
      x={x}
      y={y}
      width="30"
      height="30"
      rx="10"
      ry="10"
      fill="#101010"
      id="smallCategory"
    />
    <image
      z={10}
      y={y + 5}
      x={x + 5}
      href={icon}
      width={20}
      height={20}
      id="smallCategory"
    />
  </g>
);
