import { type RefObject, useEffect, useRef, useState } from "react";
import { MapInteractionCSS } from "react-map-interaction";

const PageNavigation = ({
  children,
  childRef,
}: {
  children: Element;
  childRef: RefObject<HTMLDivElement>;
}) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "auto";
    };
  }, []);
  const [value, setValue] = useState({
    scale: 1,
    translation: {
      x: window.innerWidth / 2 - 1300,
      y: window.innerHeight / 2 - 1300,
    },
  });
  // This timeout is for removing the boundaries style
  const borderShadowTimeoutRef = useRef<number>();
  // the function is activated when the user drags or zooms the page
  const handlePageChange = (newValue: {
    scale: number;
    translation: { x: number; y: number };
  }) => {
    const { width: expensesWidth, height: expensesHeight } =
      childRef.current.getBoundingClientRect();
    const { x, y } = newValue.translation;
    if (
      // checking whether the user has reached the edge
      x === window.innerWidth - expensesWidth ||
      x === 0 ||
      y === expensesHeight ||
      y === 0
    ) {
      if (borderShadowTimeoutRef.current) {
        clearTimeout(borderShadowTimeoutRef.current);
      }
      childRef.current.style.boxShadow = "inset 0 0 10px #4a3100";
      borderShadowTimeoutRef.current = setTimeout(
        () => (childRef.current.style.boxShadow = ""),
        800
      );
    } else {
      childRef.current.style.boxShadow = "";
    }
    setValue(newValue);
  };
  return (
    <MapInteractionCSS
      minScale={1}
      maxScale={3}
      translationBounds={{
        xMin:
          window.innerWidth - childRef.current?.getBoundingClientRect().width,
        xMax: 0,
        yMin:
          window.innerHeight - childRef.current?.getBoundingClientRect().height,
        yMax: 0,
      }}
      value={value}
      onChange={handlePageChange}
    >
      {children}
    </MapInteractionCSS>
  );
};

export default PageNavigation;
