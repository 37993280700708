import React from "react";
import type { FC, CSSProperties, SyntheticEvent } from "react";
import cl from "./CurrencyMenu.module.scss";
import { currenciesNames } from "utils/constants/currencies";
import white_cash_logo from "image/login_p/choose_currency/white_cash_logo.svg";
import { useLocation } from "react-router-dom";
import currencyToSymbolMap from "currency-symbol-map";
import { currencies } from "utils/constants/currencies";

interface Input {
  onClick?: (e: SyntheticEvent<HTMLLIElement>) => void;
  style: CSSProperties;
}

const CurrencyMenu: FC<Input> = ({ onClick, style }) => {
  const location = useLocation();
  const isCreateSpacePage = location.pathname === "/space/create";
  return (
    <div
      className={`${cl.container} !rounded-tl-none ${
        isCreateSpacePage ? cl.createSpace : ""
      }`}
      style={style}
    >
      <p>
        <img src={white_cash_logo} alt="is loading" />
        Currency
      </p>
      <ul className={cl.ul}>
        {currenciesNames.map((item) => {
          return (
            <li onClick={onClick} key={item} data-text={item}>
              <span className="text-orange mr-2">
                {currencyToSymbolMap(currencies[item])}
              </span>
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default CurrencyMenu;
