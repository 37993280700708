import React from "react";
import Loader from "utils/components/Loader/Loader";

interface WithLoaderProps {
  isLoading: boolean;
}

const withLoader = <P extends object>(Component: React.ComponentType<P>) => {
  return class WithLoaderComponent extends React.Component<
    P & WithLoaderProps
  > {
    render() {
      const { isLoading, ...props } = this.props;
      if (isLoading) {
        return <Loader />;
      }
      return <Component {...(props as P)} />;
    }
  };
};
export default withLoader;
