import { type SubmitHandler, useForm } from "react-hook-form";
import Form from "../CreatePayment/Form";
import type { IFormInputs } from "../CreatePayment/FormContainer";
import { zodResolver } from "@hookform/resolvers/zod";
import { valSchema } from "utils/validators/PeriodicSpends/createPayment";
import type { Dispatch, SetStateAction } from "react";
import { useMutation } from "@tanstack/react-query";
import showErrorMessage from "utils/constants/showErrorMessage";
import { queryClient } from "main";
import useStore from "store";
import { createSpendBody } from "../localUtils";
import { PeriodicSpendsAPI } from "services/API/PeriodicSpends";

const getElementIdByName = (arr: any[] | undefined, name: string) => {
  return arr?.filter((el) => el.title === name)[0].id?.toString();
};

const EditSpendFormContainer = ({
  setEditingSpend,
  spendTitle,
  spendId,
  spendAmount,
  accountName,
  categoryName,
}: {
  spendTitle: string;
  spendAmount: string;
  spendId: number;
  setEditingSpend: Dispatch<SetStateAction<boolean>>;
  accountName: string;
  categoryName: string;
}) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ["edit periodic spend"],
    mutationFn: PeriodicSpendsAPI.editSpend,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get periodic spends"] });
      setEditingSpend(false);
    },
    onError: showErrorMessage,
  });
  const { accounts, categories } = useStore();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      title: spendTitle,
      amount: spendAmount.toString(),
      day: "M",
      account_pk: getElementIdByName(accounts, accountName),
      category_pk: getElementIdByName(categories, categoryName),
    },
    resolver: zodResolver(valSchema),
  });
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    mutate({ data: createSpendBody(data), spendId: spendId });
  };
  return (
    <Form
      errors={errors}
      isLoading={isPending}
      register={register}
      setCreatingPayment={setEditingSpend}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitButtonText="Edit"
      accounts={accounts}
      categories={categories}
    />
  );
};

export default EditSpendFormContainer;
