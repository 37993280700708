export const currencies: Record<string, string> = {
  "United States Dollar": "USD",
  Euro: "EUR",
  "Japanese Yen": "JPY",
  "Pound Sterling": "GBP",
  "Swiss Franc": "CHF",
  "Canadian Dollar": "CAD",
  "Australian Dollar": "AUD",
  "Swedish Krona": "SEK",
  "Norwegian Krone": "NOK",
  "Danish Krone": "DKK",
  "Afghan Afghani": "AFN",
  "Algerian Dinar": "DZD",
  "Angolan Kwanza": "AOA",
  "Argentine Peso": "ARS",
  "Armenian Dram": "AMD",
  "Aruban Guilder": "AWG",
  "Azerbaijani Manat": "AZN",
  "Bahamian Dollar": "BSD",
  "Bahraini Dinar": "BHD",
  "Bangladeshi Taka": "BDT",
  "Barbadian Dollar": "BBD",
  "Belarusian Ruble": "BYN",
  "Belize Dollar": "BZD",
  "Bermudian Dollar": "BMD",
  "Bhutanese Ngultrum": "BTN",
  "Bolivian Boliviano": "BOB",
  "Bosnia-Herzegovina Convertible": "BAM",
  "Botswana Pula": "BWP",
  "Brazilian Real": "BRL",
  "Brunei Dollar": "BND",
  "Bulgarian Lev": "BGN",
  "Burundi Franc": "BIF",
  "CFA Franc BCEAO": "XOF",
  "CFA Franc BEAC": "XAF",
  "Cambodian Riel": "KHR",
  "Cape Verde Escudo": "CVE",
  "Cayman Islands Dollar": "KYD",
  "Central African CFA Franc": "XAF",
  "Chilean Peso": "CLP",
  "Chinese Yuan Renminbi": "CNY",
  "Colombian Peso": "COP",
  "Comorian Franc": "KMF",
  "Congolese Franc": "CDF",
  "Costa Rican Colon": "CRC",
  "Croatian Kuna": "HRK",
  "Cuban Peso": "CUP",
  "Czech Koruna": "CZK",
  "Djiboutian Franc": "DJF",
  "Dominican Peso": "DOP",
  "East Caribbean Dollar": "XCD",
  "Egyptian Pound": "EGP",
  "Emirati Dirham": "AED",
  "Ethiopian Birr": "ETB",
  "Falkland Islands Pound": "FKP",
  "Fijian Dollar": "FJD",
  "Gambian Dalasi": "GMD",
  "Georgian Lari": "GEL",
  "Ghanaian Cedi": "GHS",
  "Gibraltar Pound": "GIP",
  "Guatemalan Quetzal": "GTQ",
  "Guinean Franc": "GNF",
  "Guyanese Dollar": "GYD",
  "Haitian Gourde": "HTG",
  "Honduran Lempira": "HNL",
  "Hong Kong Dollar": "HKD",
  "Hungarian Forint": "HUF",
  "Icelandic Krona": "ISK",
  "Indian Rupee": "INR",
  "Indonesian Rupiah": "IDR",
  "Iranian Rial": "IRR",
  "Iraqi Dinar": "IQD",
  "Israeli New Shekel": "ILS",
  "Jamaican Dollar": "JMD",
  "Jordanian Dinar": "JOD",
  "Kazakhstani Tenge": "KZT",
  "Kenyan Shilling": "KES",
  "Kuwaiti Dinar": "KWD",
  "Kyrgyzstani Som": "KGS",
  "Lao Kip": "LAK",
  "Lebanese Pound": "LBP",
  "Lesotho Loti": "LSL",
  "Liberian Dollar": "LRD",
  "Libyan Dinar": "LYD",
  "Macanese Pataca": "MOP",
  "Macedonian Denar": "MKD",
  "Malagasy Ariary": "MGA",
  "Malawian Kwacha": "MWK",
  "Malaysian Ringgit": "MYR",
  "Maldivian Rufiyaa": "MVR",
  "Mauritian Rupee": "MUR",
  "Mexican Peso": "MXN",
  "Moldovan Leu": "MDL",
  "Mongolian Tugrik": "MNT",
  "Moroccan Dirham": "MAD",
  "Mozambican Metical": "MZN",
  "Myanmar Kyat": "MMK",
  "Namibian Dollar": "NAD",
  "Nepalese Rupee": "NPR",
  "Netherlands Antillean Guilder": "ANG",
  "New Israeli Sheqel": "ILS",
  "New Zealand Dollar": "NZD",
  "Nicaraguan Cordoba Oro": "NIO",
  "Nigerian Naira": "NGN",
  "North Korean Won": "KPW",
  "Omani Rial": "OMR",
  "Pakistani Rupee": "PKR",
  "Panamanian Balboa": "PAB",
  "Papua New Guinean Kina": "PGK",
  "Paraguayan Guarani": "PYG",
  "Peruvian Nuevo Sol": "PEN",
  "Philippine Peso": "PHP",
  "Polish Zloty": "PLN",
  "Qatari Riyal": "QAR",
  "Romanian Leu": "RON",
  "Russian Ruble": "RUB",
  "Rwandan Franc": "RWF",
  "Saint Helena Pound": "SHP",
  "Samoan Tala": "WST",
  "Saudi Riyal": "SAR",
  "Seychellois Rupee": "SCR",
  "Sierra Leonean Leone": "SLL",
  "Singapore Dollar": "SGD",
  "Solomon Islands Dollar": "SBD",
  "Somali Shilling": "SOS",
  "South African Rand": "ZAR",
  "South Korean Won": "KRW",
  "South Sudanese Pound": "SSP",
  "Sri Lankan Rupee": "LKR",
  "Sudanese Pound": "SDG",
  "Surinamese Dollar": "SRD",
  "Swazi Lilangeni": "SZL",
  "Syrian Pound": "SYP",
  "New Taiwan Dollar": "TWD",
  "Tajikistani Somoni": "TJS",
  "Tanzanian Shilling": "TZS",
  "Thai Baht": "THB",
  "Trinidad and Tobago Dollar": "TTD",
  "Tunisian Dinar": "TND",
  "Turkish Lira": "TRY",
  "Turkmenistan New Manat": "TMT",
  "Ugandan Shilling": "UGX",
  "Ukrainian Hryvnia": "UAH",
};

export const currenciesNames = Object.keys(currencies);
