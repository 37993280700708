import React, { useState } from "react";
import currencyToSymbolMap from "currency-symbol-map";
import type { ITotalBalance } from "utils/types/response/AccountRes";
import TotalTransaction from "./TotalTransaction";

const ShowTotalBalance = React.memo(
  ({ TotalBalance }: { TotalBalance: ITotalBalance }) => {
    const [showFullBalance, setShowFullBalance] = useState<boolean>(false);
    const { balance, formatted_balance, total_income_this_month, total_expenses_this_month, currency } = TotalBalance;
    const currencySymbol = currencyToSymbolMap(currency) as string;

    return (
      <article>
        <section
          onMouseLeave={() => setShowFullBalance(false)}
          onMouseEnter={() => setShowFullBalance(true)}
          className="text-white text-[18px] relative bg-gradient-to-t flex flex-col items-center from-[#ff5c02] to-[#ff7528] px-1 text-center rounded-[7px]"
        >
          {showFullBalance && (
            <p className="!absolute border border-[#252525] -top-6 bg-[#19191A] px-2 rounded-[7px]">
              {currencySymbol + Number.parseFloat(balance)}
            </p>
          )}
          <p className="text-[15px]">Total balance</p>
          <p>{currencySymbol + formatted_balance}</p>
        </section>
        <section
          className="bg-[#2D2D2E] mt-0.5 p-1 items-center grid grid-cols-[minmax(0,1fr)_minmax(0,4fr)] grid-rows-[repeat(2,auto_5px)] w-full rounded-[7px]"
        >
          <TotalTransaction data={total_income_this_month} currencySymbol={currencySymbol} transactionType="Incoming" />
          <TotalTransaction data={total_expenses_this_month} currencySymbol={currencySymbol} transactionType="Expenses" />
        </section>
      </article>);
  }
);

export default ShowTotalBalance;
