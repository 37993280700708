import getSpaceId from "utils/constants/getSpaceId";
import { instance } from "./instances";
import { AxiosResponse } from "axios";

export const DowtAPI = {
  getAdviceNumber: (): Promise<AxiosResponse<{ advices_left: number }>> =>
    instance.get(`/my_spaces/${getSpaceId()}/dowt/advice/remained/`),
  getFirstTypeOfAdvice: (period: "30_days" | "month_to_date") =>
    instance.post(`/my_spaces/${getSpaceId()}/dowt/advice/`, {
      time_range: period,
    }),
  getSecondTypeOfAdvice: (period: "30_days" | "month_to_date") =>
    instance.post(`my_spaces/${getSpaceId()}/dowt/advice/detail/`, {
      time_range: period,
    }),
};
