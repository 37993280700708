import cl from "./Tooltip.module.scss";

const Tooltip = ({ onMouseLeave }: { onMouseLeave: () => void }) => {
  return (
    <div onMouseLeave={onMouseLeave} className={cl.tooltip}>
      1.0 — In this version, the advice is based on an analysis of your total
      income and expenses.
      <br /> 2.0 — In this version, we base our analysis on your history of
      expenses, income, and transfers. This allows Dowt to provide significantly
      more detailed financial advice compared to version 1.0.
    </div>
  );
};

export default Tooltip;
