import { SpaceListResponse } from "utils/types/response/SpaceResponse";
import type { StateCreator } from "zustand";

interface State {
  spaces: SpaceListResponse[];
}

interface Actions {
  setSpaces: (spaces: State["spaces"]) => void;
}

export interface SpaceSlice extends State, Actions {}

export const createSpaceSlice: StateCreator<SpaceSlice> = (set) => ({
  spaces: [],
  setSpaces: (spaces) => set(() => ({ spaces })), // Сбрасываем флаг после установки категорий
});
