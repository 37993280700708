import React from "react";
import ConfEmail from "./ConfEmail";
import useStore from "store/store";
import { SubmitHandler, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { valSchema } from "utils/validators/Login/ConfEmail";
import { useNavigate } from "react-router-dom";

const ConfEmailContainer = () => {
  const { code, setCode } = useStore((state) => state);

  interface Input {
    code: string;
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Input>({
    resolver: zodResolver(valSchema),
  });

  const navigate = useNavigate();
  const onSubmit: SubmitHandler<Input> = async ({ code }) => {
    setCode(code);
    navigate("/login/choose-currency");
  };

  return (
    <ConfEmail
      code={code}
      register={register}
      onSubmit={onSubmit}
      handleSubmit={handleSubmit}
      errors={errors}
    />
  );
};

export default ConfEmailContainer;
