import Form from "./Form";
import type { Dispatch, SetStateAction } from "react";
import CurrencyMenu from "components/LoginPage/ChooseCurrency/CurrencyMenu";
import Title from "./Title";
import Safe from "image/expenses/Safe.svg";
import type {
  SubmitHandler,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import type { IFormInputs } from "./CreateAccountContainer";
import withLoader from "utils/hoc/withLoader";

interface Props {
  setModal: Dispatch<SetStateAction<boolean>>;
  errors: { [key: string]: { message: string } };
  register: UseFormRegister<IFormInputs>;
  handleSubmit: UseFormHandleSubmit<IFormInputs>;
  onSubmit: SubmitHandler<IFormInputs>;
  setValue: UseFormSetValue<IFormInputs>;
}

const CreateAccount: React.FC<Props> = ({
  setModal,
  errors,
  register,
  handleSubmit,
  onSubmit,
  setValue,
}) => {
  return (
    <div className="grid grid-cols-[minmax(570px, 1fr)_minmax(auto, 80vw)] grid-rows-[180px_380px] font-bold">
      <Title
        image={Safe}
        // TODO there is necessary to insert a normal text
        title={`Fill the required fields
                If you forget password please to remember!!!`}
        header="Create account"
      />
      <Form
        errors={errors}
        register={register}
        handleSubmit={handleSubmit}
        setModal={setModal}
        onSubmit={onSubmit}
        // the text displayed on the submit button
        submitText="Create account"
      />
      <CurrencyMenu
        onClick={(e) =>
          setValue("currency", e.target.dataset.text, {
            shouldValidate: true,
          })
        }
        style={{ gridRow: "1/3" }}
      />
    </div>
  );
};

export default withLoader(CreateAccount);
