import { type SubmitHandler, useForm } from "react-hook-form";
import Form from "../CreateGoal/Form";
import type { IFormInputs } from "../CreateGoal/FormContainer";
import { zodResolver } from "@hookform/resolvers/zod";
import { valSchema } from "utils/validators/Goals/CreateGoal";
import type { Dispatch, SetStateAction } from "react";
import { useMutation } from "@tanstack/react-query";
import { GoalsAPI } from "services/API/Goals";
import showErrorMessage from "utils/constants/showErrorMessage";
import { queryClient } from "main";

const EditGoalFormContainer = ({
  setGoalEvent,
  goalTitle,
  goalId,
  goal,
}: {
  goalTitle: string;
  goal: string;
  goalId: number;
  setGoalEvent: Dispatch<SetStateAction<"transfering" | "editing" | "none">>;
}) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ["edit goal"],
    mutationFn: GoalsAPI.editGoal,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["get goals"] });
      setGoalEvent("none");
    },
    onError: showErrorMessage,
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    defaultValues: {
      title: goalTitle,
      goal,
    },
    resolver: zodResolver(valSchema),
  });
  const onSubmit: SubmitHandler<IFormInputs> = (data) => {
    mutate({ goalId, goal: Number.parseFloat(data.goal), title: data.title });
  };
  return (
    <Form
      errors={errors}
      isLoading={isPending}
      register={register}
      setCreatingGoal={(arg) => setGoalEvent(arg ? "transfering" : "none")}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      submitButtonText="Edit"
    />
  );
};

export default EditGoalFormContainer;
