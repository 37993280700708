import React, { RefObject, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SpaceAPI } from "services/API.ts";
import styles from "./space_list.module.scss";
import {
  SpaceListResponse,
  CircleItem,
} from "utils/types/response/SpaceResponse";
import { Link } from "react-router-dom";

import SettingsIcon from "image/spaces_p/Settings.svg";
import AngleRightIcon from "image/spaces_p/AngleRightIcon.svg";
import AngleLeftIcon from "image/spaces_p/AngleLeftIcon.svg";
import PlusIcon from "image/spaces_p/Plus.svg";

interface Props {
  visibleItems: any[];
  circleItems: CircleItem[];
  selectedIndex: number;
  handleCircleSelect: (index: number) => void;
  getIconForMembers: (count: number) => string;
  spaces: SpaceListResponse[];
  handleEditSpace: () => void;
  handlePrevSpace: () => void;
  handleLeaveSpace: () => void;
  handleOpenSpace: () => void;
  handleNextSpace: () => void;
  isLimitReached: boolean;
}

const SpaceList: React.FC<Props> = ({
  visibleItems,
  circleItems,
  selectedIndex,
  handleCircleSelect,
  getIconForMembers,
  spaces,
  handleEditSpace,
  handlePrevSpace,
  handleLeaveSpace,
  handleOpenSpace,
  handleNextSpace,
  isLimitReached,
}) => {
  // I mean that "Come in"/"Create" button
  const centralButtonDisabled =
    spaces.length === 0 ||
    (circleItems[selectedIndex]?.id.startsWith("create") && isLimitReached);

  return (
    <div className={styles.spaceListPage} tabIndex={0}>
      <div className={styles.titleBody}>
        <Link to="/" className={styles.title}>
          ← Home
        </Link>
        <div className={styles.spaceList}>
          <div className={styles.spaceListHeader}>
            <div className={styles.spaceCircleContainer}>
              {visibleItems.map((item) => {
                const itemIndex = circleItems.indexOf(item);
                let fadeClass = "";

                if (
                  itemIndex === selectedIndex - 1 ||
                  itemIndex === selectedIndex + 1
                ) {
                  fadeClass = styles.fadeLevel1;
                } else if (
                  itemIndex === selectedIndex - 2 ||
                  itemIndex === selectedIndex + 2
                ) {
                  fadeClass = styles.fadeLevel2;
                }

                return (
                  <div
                    key={item.id}
                    className={`${styles.spaceCircle} ${
                      itemIndex === selectedIndex ? styles.selected : ""
                    } ${fadeClass} ${
                      item.id.startsWith("create") ? styles.plusButton : ""
                    }`}
                    onClick={() => handleCircleSelect(itemIndex)}
                  >
                    {item.id.startsWith("create") ? (
                      <img
                        src={PlusIcon}
                        alt="Plus"
                        className={styles.plusIcon}
                      />
                    ) : (
                      <img
                        src={getIconForMembers(item.members_count as number)}
                        alt={`${item.members_count} users`}
                        className={styles.spaceIcon}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          {spaces.length > 0 &&
            selectedIndex > 0 &&
            selectedIndex < circleItems.length - 1 && (
              <div className={styles.spaceInfo}>
                <div className={styles.spaceTitleContainer}>
                  <span className={styles.spaceTitle}>
                    {spaces[selectedIndex - 1].title}
                  </span>
                  <button
                    className={styles.editButton}
                    onClick={handleEditSpace}
                  >
                    <img
                      src={SettingsIcon}
                      alt="Edit"
                      className={styles.settingsIcon}
                    />
                  </button>
                </div>
                <span className={styles.membersCount}>
                  {spaces[selectedIndex - 1].members_count} members
                </span>
                <div className={styles.currencyWrapper}>
                  <span className={styles.currency}>
                    {spaces[selectedIndex - 1].currency}
                  </span>
                </div>
              </div>
            )}
          <div className={styles.pageIndicators}>
            {circleItems.map((_, index) => (
              <div
                key={index}
                className={`${styles.pageIndicator} ${
                  index === selectedIndex ? styles.active : ""
                }`}
              ></div>
            ))}
          </div>
          <div className={styles.spaceActions}>
            <div className={styles.spaceActionBtns}>
              <button
                onClick={handlePrevSpace}
                className={styles.spaceActionBtnCircle}
              >
                <img src={AngleLeftIcon} alt="Previous" />
              </button>
              <div className={styles.actionButtonsContainer}>
                {!circleItems[selectedIndex]?.id.startsWith("create") && (
                  <span
                    className={styles.leaveSpaceText}
                    onClick={handleLeaveSpace}
                  >
                    Leave Space
                  </span>
                )}
                <button
                  className={styles.spaceActionBtn}
                  disabled={centralButtonDisabled}
                  onClick={handleOpenSpace}
                >
                  {circleItems[selectedIndex]?.id.startsWith("create")
                    ? "Create"
                    : "Come in"}
                </button>
              </div>
              <button
                onClick={handleNextSpace}
                className={styles.spaceActionBtnCircle}
              >
                <img src={AngleRightIcon} alt="Next" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpaceList;
