import Title from "../CreateAccount/Title";
import Check from "image/Account/Check.svg";
import React, { type Dispatch, type SetStateAction } from "react";
import TransactionsFormContainer from "./TransactionsFormContainer";
import { useMutation } from "@tanstack/react-query";
import { AccountAPI } from "services/API/Account";
import type { ISpendAccountReq } from "utils/types/request/AccountRequest";
import { queryClient } from "main";
import showErrorMessage from "utils/constants/showErrorMessage";
import useStore from "store";

const IncomingModal = React.memo(
  ({
    setModal,
    accountId,
  }: {
    setModal: Dispatch<SetStateAction<boolean>>;
    accountId: number;
  }) => {
    const { setShowSpinner } = useStore();
    const { mutate, isPending } = useMutation({
      mutationKey: ["spend account"],
      mutationFn: (data: ISpendAccountReq) => {
        setModal(false);
        setShowSpinner(true);
        data.account_pk = accountId;
        return AccountAPI.SpendAccount(data);
      },
      onSuccess: () => {
        setModal(false);
        queryClient.invalidateQueries({ queryKey: ["get accounts"] });
        queryClient.invalidateQueries({ queryKey: ["get history"] });
      },
      onError: showErrorMessage,
      onSettled: () => setShowSpinner(false),
    });
    return (
      <>
        <Title
          image={Check}
          header={"Enter expense/loss"}
          title={
            "Enter the amount of money lost\n and comment (this is optional)"
          }
          className="rounded-tr-[24px]"
        />
        <TransactionsFormContainer
          setModal={setModal}
          mutate={mutate}
          isLoading={isPending}
          firstInputClass="bg-course_down"
        />
      </>
    );
  }
);

export default IncomingModal;
