import useStore from "store/store";
import currencyToSymbolMap from "currency-symbol-map";
import { useRef } from "react";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";

const Accounts = () => {
  const { accounts } = useStore();
  const accountsRef = useRef<HTMLElement>();
  useSetCoolScroll(accountsRef);

  return (
    <section
      ref={accountsRef}
      className="w-full px-5 py-3 font-normal bg-[#141414] overflow-auto flex-grow rounded-xl"
    >
      {accounts?.map((el) => (
        <div
          className="bg-[#1b1b1b] flex justify-between text-[18px] px-4 py-2 mb-2 rounded-md"
          key={el.id}
        >
          <span>{el.title}</span>
          <span>
            {el.balance_converted}
            <span className="text-[#FFA800]">
              {currencyToSymbolMap(el.currency)}
            </span>
          </span>
        </div>
      ))}
    </section>
  );
};
export default Accounts;