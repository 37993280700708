import { useSpring, animated } from "@react-spring/web";
import { Dispatch, SetStateAction } from "react";

const SubscriptionLimitMessage = ({
  setActive,
  text,
}: {
  setActive: Dispatch<SetStateAction<boolean>>;
  text: string;
}) => {
  const springs = useSpring({
    from: { transform: "scale(0)" },
    to: { transform: "scale(1)" },
  });

  return (
    <animated.main
      className="text-white p-5 text-xl rounded-2xl grid gap-x-3 gap-y-2 grid-cols-[auto_auto_auto]"
      style={{ ...springs }}
    >
      <p className="max-w-[500px] w-[70dvw] col-[1/4]">{text}</p>
      <button className=" bg-[#191a1c] px-5 py-1 rounded-xl col-[2/3] ">
        Upgrade
      </button>
      <button
        onClick={() => setActive(false)}
        className="hover:bg-[#e09300] duration-300 bg-orange text-lg text-black rounded-xl px-5 py-1 "
      >
        OK
      </button>
    </animated.main>
  );
};
export default SubscriptionLimitMessage;
