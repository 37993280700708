import { CustomAlertContext } from "AppContainer";
import Lock from "image/lock";
import { useContext } from "react";

const Block = () => {
  const showCustomAlert = useContext(CustomAlertContext);
  return (
    <button
      className="w-full h-full bg-[rgba(0,0,0,0.6)] flex left-0 top-0 absolute rounded-2xl z-10"
      onClick={() =>
        showCustomAlert(
          "Your previous subscription has expired. You no longer have access to this item because it is not part of your current subscription",
        )
      }
    >
      <span className="m-auto size-14">
        <Lock color="#FFA800" />
      </span>
    </button>
  );
};

export default Block;
