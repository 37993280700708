import React from "react";
import { useState } from "react";
import cl from "./ChooseDay.module.scss";
import type { UseFormRegister } from "react-hook-form";
import type { IFormInputs } from "../FormContainer";
import arrow_left from "image/arrow_left.svg";
import arrow_right from "image/arrow_right.svg";
import { daysOfWeek } from "../../localUtils";

const ChooseDay = ({
  register,
}: {
  register: UseFormRegister<IFormInputs>;
}) => {
  const dates = Array.from({ length: 31 }, (_, i) => i + 1);
  const [selectedWeekday, setSelectedWeekday] = useState<
    (typeof daysOfWeek)[number] | null
  >("M");
  const [selectedDate, setSelectedDate] = useState<number | null>(null);
  const [onceAWeek, setOnceAWeek] = useState<boolean>(true);
  const handleClick = () => {
    setOnceAWeek((prev) => !prev);
  };

  return (
    <section className={cl.container}>
      <button onClick={handleClick} type="button">
        <img src={arrow_left} />
      </button>
      <p>{onceAWeek ? "Once a week" : "Once a month"}</p>
      <button onClick={handleClick} type="button">
        <img src={arrow_right} alt="arrow right" />
      </button>
      {onceAWeek ? (
        <div
          key="just"
          className="flex col-[1/4] text-2xl text-[#747474] justify-evenly w-full"
        >
          {daysOfWeek.map((day, i) => (
            <React.Fragment key={day}>
              <input {...register("day")} id={day} type="radio" value={day} />
              <label
                htmlFor={day}
                onClick={() => {
                  setSelectedWeekday(day);
                  setSelectedDate(null);
                }}
                className={selectedWeekday === day ? cl.activeLabel : undefined}
                name="day"
              >
                {day}
              </label>
            </React.Fragment>
          ))}
        </div>
      ) : (
        <div
          key="aaaa"
          className="col-[1/4] gap-y-2 text-[#747474] select-none w-full grid justify-items-center grid-cols-10"
        >
          {dates.map((date) => (
            <React.Fragment key={`${date}ok`}>
              <input
                type="radio"
                id={`${date}id`}
                {...register("day")}
                // name="date of month"
                value={date}
              />
              <label
                htmlFor={`${date}id`}
                onClick={() => {
                  setSelectedDate(date);
                  setSelectedWeekday(null);
                }}
                className={`
                  text-xl
                  ${selectedDate === date ? cl.activeLabel : undefined}`}
              >
                {date}
              </label>
            </React.Fragment>
          ))}
        </div>
      )}
    </section>
  );
};

export default ChooseDay;
