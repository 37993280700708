import React from "react";
import conf_logo from "image/login_p/conf_email/conf_logo.svg";
import { UseFormRegister, UseFormHandleSubmit } from "react-hook-form";
import cl from "./ConfEmail.module.scss";
import withLoader from "utils/hoc/withLoader";
import withErrorShow from "utils/hoc/withErrorShow";

interface Input {
  code: string;
}

interface Props {
  register: UseFormRegister<Input>;
  onSubmit: (data: Input) => void;
  handleSubmit: UseFormHandleSubmit<Input>;
}

const ConfEmail: React.FC<Props> = ({ register, onSubmit, handleSubmit }) => {
  return (
    <>
      <div className={cl.title}>
        <img src={conf_logo} alt="is loading" />
        <div className="max-w-[234px]">
          <p className="mb-1">Write code</p>
          <p className="text-[14px] text-[#6E6E6E]">
            We have sent a confirmation code message to your email, please check
            and enter the code.
          </p>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className={cl.form}>
        <input {...register("code")} placeholder="Code" autoComplete="off" />
        <button type="submit">Done</button>
      </form>
    </>
  );
};

export default withErrorShow(withLoader(ConfEmail));
