import type { RefObject, Dispatch, SetStateAction } from "react";
import Spend from "./Spend";
import plus_logo from "image/Goals/plus.svg";
import Modal from "utils/components/Modal";
import CreatePayment from "./CreatePayment";
import Loader from "../Notifications/NotificationsWindow/Loader";
import { Spend as SpendInterface } from "utils/types/response/PeriodicSpendsRes";

interface PeriodicSpendsWindowProps {
  featureRef: RefObject<HTMLElement>;
  contentRef: RefObject<HTMLDivElement>;
  onBack: () => void;
  windowSize: any;
  isPending: boolean;
  data: SpendInterface[] | undefined;
  resizeRef: RefObject<HTMLDivElement>;
  creatingPayment: boolean;
  setCreatingPayment: Dispatch<SetStateAction<boolean>>;
  isLimitReached: boolean;
  limit: number;
}

const PeriodicSpendsWindow = ({
  featureRef,
  onBack,
  windowSize,
  contentRef,
  isPending,
  data,
  resizeRef,
  creatingPayment,
  setCreatingPayment,
  isLimitReached,
  limit,
}: PeriodicSpendsWindowProps) => {
  return (
    <aside
      ref={featureRef}
      className="bg-[#121212] relative top-[2.3%] left-[1.5%] overflow-hidden rounded-3xl flex flex-col items-center shadow-md shadow-[#FFA80080]"
      style={{
        width: `${windowSize.width}px`,
        height: `${windowSize.height}px`,
        minWidth: "432px",
        minHeight: "750px",
        maxWidth: "510px",
        maxHeight: "870px",
      }}
    >
      <div
        ref={contentRef}
        className="w-full h-full overflow-auto flex flex-col items-center"
      >
        <div className="flex font-vela items-center justify-between w-full p-4">
          <button
            onClick={onBack}
            className="text-2xl bg-transparent border-none cursor-pointer text-white hover:text-[#ffa800] transition-colors duration-200"
          >
            ←
          </button>
          <h2 className="text-[20px] text-white flex-grow text-center">
            Periodic Spends
          </h2>
        </div>
        <div className="flex flex-col items-center w-full">
          {isPending ? (
            <Loader beforeBg="18,18,18" />
          ) : (
            data?.map(
              (
                {
                  title,
                  schedule,
                  category,
                  account,
                  id,
                  day,
                  amount,
                  currency,
                  category_icon,
                },
                index,
              ) => (
                <Spend
                  title={title}
                  schedule={schedule}
                  id={id}
                  day={day}
                  key={`${title} payment`}
                  amount={amount}
                  currency={currency}
                  account={account}
                  category={category}
                  category_icon={category_icon}
                  isBlocked={index + 1 > limit}
                />
              ),
            )
          )}
        </div>
        {!isLimitReached && (
          <button
            className="mt-4 mb-4"
            onClick={() => setCreatingPayment(true)}
            type="button"
          >
            <img src={plus_logo} className="!rounded-full" alt="add payment" />
          </button>
        )}
      </div>
      <div
        ref={resizeRef}
        className="absolute bottom-0 right-0 w-4 h-4 bg-[#ffa800] cursor-nwse-resize"
        style={{
          borderTopLeftRadius: "25px",
        }}
      ></div>
      {creatingPayment && (
        <Modal setActive={setCreatingPayment}>
          <CreatePayment setCreatingPayment={setCreatingPayment} />
        </Modal>
      )}
    </aside>
  );
};

export default PeriodicSpendsWindow;
