import type { Dispatch, FC, SetStateAction } from "react";
import cl from "./Months.module.scss";
import type {
  UseMutateAsyncFunction,
  UseMutateFunction,
} from "@tanstack/react-query";
import type { AxiosError, AxiosResponse } from "axios";
import type { getBackupListRes } from "utils/types/response/MonthsRes";
import withLoader from "utils/hoc/withLoader";
import { queryClient } from "main";
import getSpaceId from "utils/constants/getSpaceId";

const increment = (prev: number) => prev + 1;
const decrement = (prev: number) => prev - 1;
const isPresentPeriod = (monthNum: number, year: number) =>
  year >= new Date().getFullYear() && monthNum >= new Date().getMonth() + 1;

interface Props {
  currentMonthNum: number;
  currentMonth: string;
  currentYear: number;
  setCurrentMonthNum: Dispatch<SetStateAction<number>>;
  setCurrentYear: Dispatch<SetStateAction<number>>;
  mutate: UseMutateFunction<
    AxiosResponse<getBackupListRes, any>,
    AxiosError<unknown, any>,
    { year: number; month: number },
    unknown
  >;
  mutateAsync: UseMutateAsyncFunction<
    AxiosResponse<getBackupListRes, any>,
    AxiosError<unknown, any>,
    { year: number; month: number },
    unknown
  >;
}

const Months: FC<Props> = ({
  currentMonth,
  currentMonthNum,
  currentYear,
  setCurrentMonthNum,
  setCurrentYear,
  mutate,
  mutateAsync,
}) => {
  const prevMonth = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    new Date().setMonth(currentMonthNum - 2),
  );
  const nextMonth = new Intl.DateTimeFormat("en-US", { month: "long" }).format(
    new Date().setMonth(currentMonthNum),
  );
  const spaceId = getSpaceId();
  const LeftButton = () => (
    <button
      type="button"
      className="rounded-l-2xl"
      onClick={() => {
        if (currentMonthNum === 1) {
          mutateAsync({ month: 12, year: currentYear - 1 }).then(() => {
            setCurrentMonthNum(12);
            setCurrentYear(decrement);
          });
        } else {
          mutateAsync({ month: currentMonthNum - 1, year: currentYear }).then(
            () => setCurrentMonthNum(decrement),
          );
        }
      }}
    >
      &larr; {prevMonth}
    </button>
  );
  const MiddleButton = () => (
    <p className="!text-white cursor-not-allowed border-x-2 border-x-[#6C6C6C] !text-[1.2em]">
      {currentMonth}
    </p>
  );
  const RightButton = () => (
    <button
      type="button"
      className="rounded-r-2xl"
      disabled={isPresentPeriod(currentMonthNum, currentYear)}
      onClick={() => {
        const todaysYear = new Date().getFullYear();
        // this variable contains a month number decremented by one
        const todaysMonth = new Date().getMonth();
        if (currentMonthNum === 12) {
          // case if user turning into his real-time space
          if (todaysMonth === 0 && currentYear === todaysYear - 1) {
            queryClient.invalidateQueries({ queryKey: ["get accounts"] });
          }
          setCurrentMonthNum(1);
          setCurrentYear(increment);
          if (!isPresentPeriod(1, currentYear + 1)) {
            mutate({ month: 1, year: currentYear + 1 });
          }
        } else {
          // case if user turning into his real-time space
          if (currentMonthNum === todaysMonth && currentYear === todaysYear) {
            queryClient.invalidateQueries({
              queryKey: ["get accounts"],
              exact: true,
              refetchType: "active",
            });
            queryClient.invalidateQueries({
              queryKey: ["categories", spaceId],
            });
          }
          setCurrentMonthNum(increment);
          if (!isPresentPeriod(currentMonthNum + 1, currentYear)) {
            mutate({ month: currentMonthNum + 1, year: currentYear });
          }
        }
      }}
    >
      {nextMonth} &rarr;
    </button>
  );

  return (
    <nav id="months" className={cl.container}>
      <span className="text-white absolute -top-7">{currentYear}</span>
      <LeftButton />
      <MiddleButton />
      <RightButton />
    </nav>
  );
};

export default withLoader(Months);
