import { lazy, Suspense, memo } from "react";
import "./App.scss";
import LoginPage from "./components/LoginPage";
import HomePage from "./components/HomePage";
import { Routes, Route } from "react-router-dom";
import ConfPasswd from "./components/LoginPage/ConfPasswd";
import Login from "./components/LoginPage/Login";
import ConfEmailContainer from "./components/LoginPage/ConfEmail";
import ChooseNick from "./components/LoginPage/ChooseNick";
import ChooseCurrency from "components/LoginPage/ChooseCurrency";
import SpaceListContainer from "components/Space/SpaceList/SpaceListContainer.tsx";
import withLoader from "utils/hoc/withLoader";
import StatisticsPage from "components/StatisticsPage";
import SpaceCreateContainer from "./components/Space/SpaceCreate/SpaceCreateContainer.tsx";
import SpaceEditContainer from "./components/Space/SpaceEdit/EditSpaceContainer.tsx";
import MemberEditContainer from "./components/Space/MemberEdit/MemberEditContainer.tsx";
const MobileSettings = lazy(
  () => import("components/HomePage/Overlay/SettingsWindow/MobileSettings.tsx"),
);
const VerifyEmailContainer = lazy(
  () =>
    import(
      "components/HomePage/Overlay/SettingsWindow/VerifyEmailContainer.tsx"
    ),
);

const CreateCategoryMobile = lazy(
  () =>
    import(
      "components/HomePage/Overlay/CreateCategory/Mobile/CreateCategoryMobile.tsx"
    ),
);
const EditCategoryMobileContainer = lazy(
  () =>
    import(
      "components/HomePage/Overlay/EditCategory/Mobile/EditCategoryMobileContainer.tsx"
    ),
);
import Loader from "utils/components/Loader/Loader.tsx";
const ChooseCurrencyGoogleContainer = lazy(
  () =>
    import(
      "components/LoginPage/ChooseCurrency/ChooseCurrencyGoogleContainer.tsx"
    ),
);
const NotFoundPage = lazy(
  () => import("components/NotFoundPage/NotFoundPage.tsx"),
);
const AboutUsPage = lazy(() => import("components/AboutUsPage"));
const WelcomePage = lazy(
  () => import("components/WelcomePage/WelcomePage.tsx"),
);
const PurchaseTermsPage = lazy(() => import("components/PurchaseTermsPage"));
const UserAgreementPage = lazy(() => import("components/UserAgreementPage"));
const DeleteAccountOrDataPage = lazy(
  () =>
    import("components/DeleteAccountOrDataPage/DeleteAccountOrDataPage.tsx"),
);

const App = memo(function App({ loggedIn }: { loggedIn: boolean }) {
  return (
    <Routes>
      <Route
        path="/terms-and-privacy/"
        element={
          <Suspense fallback={<Loader />}>
            <UserAgreementPage />
          </Suspense>
        }
      />
      <Route
        path="/welcome/"
        element={
          <Suspense fallback={<Loader />}>
            <WelcomePage />
          </Suspense>
        }
      />
      <Route
        path="/terms-of-purchase/"
        element={
          <Suspense fallback={<Loader />}>
            <PurchaseTermsPage />
          </Suspense>
        }
      />
      <Route
        path="/about/"
        element={
          <Suspense fallback={<Loader />}>
            <AboutUsPage />
          </Suspense>
        }
      />
      <Route
        path="/delete-account-or-data/"
        element={
          <Suspense fallback={<Loader />}>
            <DeleteAccountOrDataPage />
          </Suspense>
        }
      />
      <Route path="/" element={<HomePage loggedIn={loggedIn} />} />
      <Route path="/statistics/*" element={<StatisticsPage />} />
      <Route path="/mobileStatistics/" element={<StatisticsPage />} />
      <Route path="/login" element={<LoginPage loggedIn={loggedIn} />}>
        <Route path="" element={<Login />} />
        <Route path="confirm-password" element={<ConfPasswd />} />
        <Route path="confirm-email" element={<ConfEmailContainer />} />
        <Route path="choose-nickname" element={<ChooseNick />} />
        <Route path="choose-currency" element={<ChooseCurrency />} />
      </Route>
      <Route
        path="google/choose_currency"
        element={
          <Suspense fallback={<Loader />}>
            <ChooseCurrencyGoogleContainer />
          </Suspense>
        }
      />

      <Route
        path="*"
        element={
          <Suspense fallback={<Loader />}>
            <NotFoundPage />
          </Suspense>
        }
      />
      <Route path="/space" element={<SpaceListContainer />} />
      <Route path="/space/create" element={<SpaceCreateContainer />} />
      <Route path="/space/edit/:spaceId" element={<SpaceEditContainer />} />
      <Route
        path="/space/:spaceId/members/:memberId/edit"
        element={<MemberEditContainer />}
      />
      <Route
        path="/mobile-settings/"
        element={
          <Suspense fallback={<Loader />}>
            <MobileSettings />
          </Suspense>
        }
      />
      <Route
        path="/create-category"
        element={
          <Suspense fallback={<Loader />}>
            <CreateCategoryMobile />
          </Suspense>
        }
      />
      <Route
        path="/edit-category"
        element={
          <Suspense fallback={<Loader />}>
            <EditCategoryMobileContainer />
          </Suspense>
        }
      />
      <Route
        path="/mobile-verify-email"
        element={
          <Suspense fallback={<Loader />}>
            <VerifyEmailContainer />
          </Suspense>
        }
      />
    </Routes>
  );
});

export default withLoader(App);
