import React, { useState, lazy, Suspense } from "react";
import { animated, useTransition } from "@react-spring/web";
import MonthsContainer from "./Months";

import classes from "../home_page.module.scss";

import Loader from "utils/components/Loader/Loader";
import PremiumMenu from "./PremiumMenu";
const CreateCategory = lazy(() => import("./CreateCategory/PC"));

import useStore from "store";

// Images
import MenuOpenBtnSvg from "/src/image/p_menu/MenuOpenBtn.svg";
import Notifications from "./Notifications/Notifications";
import Spinner from "utils/components/Spinner";

interface MenuOpenBtnProps {
  setShowMenu: React.Dispatch<React.SetStateAction<boolean>>;
  menuPos: { top: string; left: string };
}

const MenuOpenBtn = ({ setShowMenu, menuPos }: MenuOpenBtnProps) => {
  return (
    <button
      type="button"
      id="MenuOpenBtn"
      onClick={() => setShowMenu(true)}
      className="rounded-3xl bg-[#1c1c1c] z-20 bg-opacity-70 p-[0.6rem] left-4 top-9"
    >
      <img
        src={MenuOpenBtnSvg}
        alt="MenuOpenBtn"
        className="bg-[#1d1f20] size-[60px] p-[9px] rounded-2xl border border-[#727171] hover:border-[#ffa800]"
      />
    </button>
  );
};

const Overlay = () => {
  const { showDialog, showSpinner } = useStore();

  const [showMenu, setShowMenu] = useState<boolean>(false);
  const menuPos: { left: string; top: string } = {
    left: "6",
    top: "6",
  };

  const transitions = useTransition(showMenu, {
    from: {
      transform: "scaleY(0)",
      transformOrigin: "top",
      opacity: 0,
    },
    enter: {
      transform: "scaleY(1)",
      transformOrigin: "top",
      opacity: 1,
    },
    leave: {
      transform: "scaleY(0.6)",
      transformOrigin: "top",
      opacity: 0,
    },
    config: { tension: 220, friction: 20 },
  });

  return (
    <div className={classes.overlay}>
      <MonthsContainer />
      <Notifications />

      {transitions((style, item) =>
        item ? (
          <animated.div style={style} className={`absolute h-dvh z-10`}>
            <PremiumMenu setShowMenu={setShowMenu} menuPos={menuPos} />
          </animated.div>
        ) : null
      )}

      {showSpinner && (
        <div className="absolute right-32 top-10 z-30">
          <Spinner />
        </div>
      )}

      {!showMenu && <MenuOpenBtn setShowMenu={setShowMenu} menuPos={menuPos} />}
      {showDialog && (
        <Suspense fallback={<Loader />}>
          <CreateCategory />
        </Suspense>
      )}
    </div>
  );
};

export default Overlay;
