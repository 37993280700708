import { useEffect, useMemo, useContext } from "react";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { AccountAPI } from "services/API/Account";
import currencyToSymbolMap from "currency-symbol-map";
import AccountCircle from "./AccountCircle";
import useStore from "store";
import Loader from "utils/components/Loader/Loader";
import { createPortal } from "react-dom";
import { CustomAlertContext } from "AppContainer";

const AccountCircleContainer = () => {
  const [creatingAccount, setCreatingAccount] = useState<boolean>(false);
  const {
    setAccounts,
    accounts,
    setTotalBalance,
    totalBalance,
    userRestrictions: { accounts: limit },
    subscription,
  } = useStore();
  const { data, isPending } = useQuery({
    queryKey: ["get accounts"],
    queryFn: () => AccountAPI.ViewAccounts(),
    refetchInterval: (query) =>
      // if it today's month then we use interval refetch
      accounts === query.state.data?.data.Account ? 5000 : false, // Опрашивать каждые 5 секунд
    refetchIntervalInBackground: true,
    // if user use the months feature we dont use query during changing tabs
    refetchOnWindowFocus: (query) =>
      accounts === query.state.data?.data.Account,
  });

  useEffect(() => {
    if (data) {
      setAccounts(data.data.Account);
      setTotalBalance(data.data.TotalBalance);
    }
  }, [data, setAccounts]);

  const isLimitReached = useMemo(
    () => (accounts ? accounts.length >= limit : false),
    [accounts, limit],
  );

  const [accountNumber, setAccountNumber] = useState<number>(
    Number.parseInt(localStorage.getItem("accountNumber")) || 0,
  );
  localStorage.setItem("accountNumber", accountNumber.toString());

  const Account = accounts ? accounts[accountNumber] : undefined;

  const blockedCurrentAccount = useMemo(() => {
    return accounts && accounts[accountNumber] && accountNumber + 1 > limit
      ? true
      : false;
  }, [accounts, accountNumber, limit]);

  const currencySymbol = Account && currencyToSymbolMap(Account?.currency);
  const showTotalBalance =
    totalBalance &&
    accounts?.length !== 0 &&
    accounts?.length === accountNumber;
  const TotalBalance = totalBalance ? totalBalance[0] : undefined;
  const disabedCircle = accounts !== data?.data.Account;

  if (isPending) {
    return createPortal(
      <Loader />,
      document.getElementById("root") as HTMLElement,
    );
  }

  const showCustomAlert = useContext(CustomAlertContext);

  return (
    <AccountCircle
      setAccountNumber={setAccountNumber}
      Account={Account}
      currencySymbol={currencySymbol}
      setCreatingAccount={setCreatingAccount}
      creatingAccount={creatingAccount}
      showTotalBalance={showTotalBalance}
      TotalBalance={TotalBalance}
      accountsLength={accounts?.length}
      disabled={disabedCircle}
      isLimitReached={isLimitReached}
      blockedCurrentAccount={blockedCurrentAccount}
      showCustomAlert={showCustomAlert}
      subscription={subscription}
    />
  );
};

export default AccountCircleContainer;
