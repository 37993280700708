import React, { type FC } from "react";
import type { UseFormRegister, FieldErrors } from "react-hook-form";
import cl from "./Field.module.scss";
import errorImg from "/src/image/login_p/login/error.svg";

interface IProps {
  register: UseFormRegister<any>;
  type: "email" | "text" | "password";
  placeholder: string;
  formValue: "password" | "email";
  className?: string;
  errors: FieldErrors;
}

const Field: FC<IProps> = ({
  register,
  formValue,
  type,
  placeholder,
  className,
  errors,
}) => {
  return (
    <div className={cl.field}>
      <input
        className={className}
        type={type}
        placeholder={placeholder}
        {...register(formValue)}
      />
      {errors[formValue] && <img src={errorImg} alt="error" />}
    </div>
  );
};

export default Field;
