import { StatisticsContext } from "components/StatisticsPage";
import { useContext } from "react";
import type { StatisticsRes } from "utils/types/response/StatisticsResponse";
import cl from "./Recurring.module.scss";
import currencyToSymbolMap from "currency-symbol-map";
import type { RecurringPayments } from "utils/types/response/StatisticsResponse";

export default ({ fontSize, height }: { fontSize: string; height: string }) => {
  const Recurring = (useContext(StatisticsContext).data as StatisticsRes)
    .Recurring_Payments;

  const { percent, period, currency } =
    useContext(StatisticsContext) && useContext(StatisticsContext);

  const chartPeriod = (period + (percent ? "_Percent" : "")) as Exclude<
    keyof RecurringPayments,
    `Analyze_${keyof RecurringPayments}`
  >;
  const data: Record<string, string> = Recurring[chartPeriod];
  const currencySymbol = currencyToSymbolMap(currency as string) as string;
  const totalSum = Object.values(data).reduce(
    (a, b) => Number.parseInt(a) + Number.parseInt(b),
    0,
  );

  return (
    <div className={cl.container} style={{ height }}>
      <p style={{ fontSize }}>Recurring payments</p>
      {!Object.keys(data).length && (
        <label className="h-[8%] flex-col items-center font-bold text-white">
          <p className="mb-10 text-[30px]">No information</p>
          <progress
            max={100}
            value={0}
            className={`h-2 rounded-[8px] ${cl.defProgress}`}
          />
        </label>
      )}
      {Object.entries(data).map(([label, value], index) => (
        <label
          key={label}
          className="items-center justify-end font-bold text-white"
        >
          <p className="grow pr-4" style={{ fontSize }}>
            {label.length > 15 ? `${label.slice(0, 15)}...` : label}
          </p>
          <progress
            max={totalSum}
            value={Number.parseInt(value)}
            className={`h-[60%] w-[50%] rounded-[8px] sm:w-[70%] ${
              cl[`progress${index + 1}`]
            }`}
          />
          <p className="w-[14%] pl-2 text-center" style={{ fontSize }}>
            {percent
              ? `%${Number.parseInt(value)}`
              : currencySymbol + Number.parseInt(value)}
          </p>
        </label>
      ))}
    </div>
  );
};
