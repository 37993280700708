import React from "react";

interface ProgressBarProps {
  usedPiecesCount: number;
  containerWidth: number; // Новое свойство для передачи ширины контейнера
}

const ProgressBar: React.FC<ProgressBarProps> = ({
                                                   usedPiecesCount,
                                                   containerWidth,
                                                 }) => {
  const barHeight = Math.max(containerWidth * 0.014, 6); // Пропорциональная высота бара, минимальное значение 6px

  return (
    <div
      className="bg-[#3a2d0a] my-4 relative rounded-xl"
      style={{ width: "100%", height: `${barHeight}px` }}
    >
      <div
        className="absolute bg-orange h-full rounded-xl"
        style={{
          width: `${usedPiecesCount * 4}%`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
