import type { AxiosResponse } from "axios";
import { instance } from "./instances";
import type { ProfileViewRes } from "utils/types/response/ProfileRes";
import type {
  IConfirmNewEmail,
  IProfileEditReq,
} from "utils/types/request/ProfileReq";

export const ProfileAPI = {
  ProfileView(): Promise<AxiosResponse<ProfileViewRes>> {
    return instance.get("/me/profile/");
  },
  Logout(): Promise<AxiosResponse> {
    return instance.post("/me/logout/");
  },
  EditProfile(data: IProfileEditReq): Promise<AxiosResponse<ProfileViewRes>> {
    return instance.put("/me/profile/edit/", data);
  },
  ConfirmNewEmail(data: IConfirmNewEmail): Promise<AxiosResponse> {
    return instance.post("/me/profile/edit/verify_new_email/", data);
  },
  VerifyEmail: (data: { reset_code: string }) =>
    instance.post(`/me/profile/edit/verify_new_password/`, data),
};
