import { z } from "zod";

export const valSchema = z.object({
  from_account: z
    .string()
    .regex(/^\d+$/)
    .refine((value) => Number(value) > 0, {
      message: "the account is not valid",
    }),
  amount: z.string().refine(
    (value) => {
      const parsedAmount = Number.parseFloat(value);
      return (
        !Number.isNaN(parsedAmount) && parsedAmount >= 0 && parsedAmount < 1e10
      );
    },
    {
      message:
        "amount must be a valid number with at most 10 integer digits and 2 decimal places",
    },
  ),
});
