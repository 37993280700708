import React, { useEffect, useRef, useState } from "react";
import { CryptoAndStocksAPI } from "services/API/CryptoAndStocks";
import type { CryptoAndStocksPricesRes } from "utils/types/response/CryptoAndStocksPricesRes";
import styles from "./CryptoAndStocks.module.scss";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";

interface CryptoAndStocksListProps {
  searchTerm: string;
}

const CryptoAndStocksList: React.FC<CryptoAndStocksListProps> = ({
  searchTerm,
}) => {
  const [data, setData] = useState<CryptoAndStocksPricesRes | null>(null);
  const listRef = useRef();
  useSetCoolScroll(listRef);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await CryptoAndStocksAPI();
        setData(response.data);
      } catch (error) {
        console.error("Failed to fetch data", error);
      }
    };
    fetchData();
  }, []);

  const getIconPath = (symbol: string) => {
    return `https://spendsplif.com/icons/crypto-stocks/${symbol}.svg`;
  };

  const filteredData = data
    ? Object.entries(data).filter(
        ([symbol, { name }]) =>
          name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          symbol.toLowerCase().includes(searchTerm.toLowerCase())
      )
    : [];

  return (
    <div className={styles.list} ref={listRef}>
      {filteredData.length > 0
        ? filteredData.map(([symbol, { name, price_usd, price_eur }]) => (
            <div key={symbol} className={styles.listItem}>
              <div className={styles.nameAndIcon}>
                <img
                  src={getIconPath(symbol)}
                  alt={symbol}
                  className={styles.icon}
                />
                <div className={styles.nameAndSymbol}>
                  <span className={styles.symbol}>{symbol}</span>
                  <span className={styles.name}>{name}</span>
                </div>
              </div>
              <div className={styles.prices}>
                <span className={styles.priceUSD}>{price_usd}$</span>
                <span className={styles.priceEUR}>{price_eur}€</span>
              </div>
            </div>
          ))
        : searchTerm
        ? "No results found"
        : "Loading..."}
    </div>
  );
};

export default CryptoAndStocksList;
