import check_mark_logo from "image/Goals/check_mark.svg";
import trash_logo from "image/Goals/trash.svg";
import edit_logo from "image/Goals/edit.svg";
import cl from "./Goal.module.scss";
import ProgressBar from "./ProgressBar";
import wallet_logo from "image/Goals/wallet.svg";
import type { goalI } from "./GoalsWindow";
import { useMutation } from "@tanstack/react-query";
import { GoalsAPI } from "services/API/Goals";
import { queryClient } from "main";
import Loader from "../Notifications/NotificationsWindow/Loader";
import { useState, type Dispatch, type FC, type SetStateAction } from "react";
import TransferingToGoal from "./TransferingToGoal";
import Modal from "utils/components/Modal";
import EditGoal from "./EditGoal";
import showErrorMessage from "utils/constants/showErrorMessage";
import smalltalk from "utils/libraries/smalltalk";
import Block from "utils/components/Block/Block";

interface Props extends Omit<goalI, "collected" | "father_space"> {
  setEvent: Dispatch<SetStateAction<boolean>>;
  isBlocked: boolean;
}

const Goal: FC<Props> = ({
  title,
  collected_percentage,
  created_date,
  created_time,
  currency,
  goal_converted,
  id,
  goal,
  collected_converted,
  isBlocked,
}) => {
  const { mutate, isPending } = useMutation({
    mutationKey: ["delete goal"],
    mutationFn: () => GoalsAPI.deleteGoal(id),
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["get goals"] }),
    onError: showErrorMessage,
  });
  const [goalEvent, setGoalEvent] = useState<
    "transfering" | "editing" | "none"
  >("none");
  const actions = [
    {
      icon: check_mark_logo,
      onClick: () => {
        smalltalk
          .confirm("Are you sure ?", "You are going to complete your goal")
          .then(mutate);
      },
    },
    { icon: edit_logo, onClick: () => setGoalEvent("editing") },
    {
      icon: trash_logo,
      onClick: () => {
        smalltalk
          .confirm("Are you sure ?", "You are going to delete your goal")
          .then(mutate);
      },
    },
  ];

  return (
    <>
      <section className={cl.container}>
        {/* button that blocks goal */}
        {isBlocked && <Block />}
        {isPending ? (
          <Loader beforeBg="24,24,24" />
        ) : (
          <>
            <div className={cl.header}>
              <p>{title.length <= 18 ? title : `${title.slice(0, 15)}...`}</p>
              {actions.map((el, i) => (
                <button
                  key={el.icon}
                  type="button"
                  className={`bg-[#3c3600cb] py-4 px-5  ${
                    i === 2 ? "rounded-tr-2xl" : ""
                  }`}
                  onClick={el.onClick}
                >
                  <img className="h-[25px]" src={el.icon} alt="action button" />
                </button>
              ))}
            </div>
            <article className="rounded-t-3xl mt-4">
              <p>Creation date</p>
              <p>{created_time}</p>
              <p className="ml-2">{created_date}</p>
            </article>
            <article>
              <p>Balance</p>
              <p className="!bg-orange !text-black">{collected_converted}</p>
              <p className="ml-2">{currency}</p>
            </article>
            <article className="rounded-b-3xl">
              <p>Progress</p>
              <ProgressBar
                firstLabel={collected_percentage}
                secondLabel={goal_converted}
                completed={collected_percentage}
              />
            </article>
            <button
              type="button"
              className="w-[90%] flex justify-center items-center rounded-xl mt-4 h-12 bg-[#242424]"
              onClick={() => setGoalEvent("transfering")}
            >
              <img
                src={wallet_logo}
                alt="wallet logo"
                className="h-[70%] mr-3"
              />
              <p className="text-xl text-[#777777]">
                Add money from my account
              </p>
            </button>
          </>
        )}
      </section>
      {goalEvent === "transfering" ? (
        <Modal setActive={(arg) => setGoalEvent(arg ? "transfering" : "none")}>
          <TransferingToGoal goalId={id} setGoalEvent={setGoalEvent} />
        </Modal>
      ) : goalEvent === "editing" ? (
        <Modal setActive={(arg) => setGoalEvent(arg ? "editing" : "none")}>
          <EditGoal
            goalId={id}
            goalTitle={title}
            goal={goal}
            setGoalEvent={setGoalEvent}
          />
        </Modal>
      ) : null}
    </>
  );
};

export default Goal;
