import type { AxiosError } from "axios";
import smalltalk from "utils/libraries/smalltalk";

const getTextFromObject = (input: object | any[]): string => {
  if (Array.isArray(input) && typeof input[0] === "string") {
    return input[0];
  }
  const firstElement = input[Object.keys(input)[0]];
  if (typeof firstElement === "string") {
    return firstElement;
  } else {
    return getTextFromObject(firstElement);
  }
};

export default (error: AxiosError) => {
  const res = error.response?.data;
  let alertMessage = "";

  if (typeof res === "object") {
    alertMessage = getTextFromObject(res);
  } else if (typeof res === "string") {
    alertMessage = res;
  }
  smalltalk.alert("Error", alertMessage);
};
