import React from "react";
import Arc from "./Arc";
import Glow from "./Glow";

export const colors = ["#ffa800", "#ffda92", "#fcc14f", "#fca644", "white"];

export default ({
  data,
  dotunRadius,
  dotunStrokeWidth,
}: {
  data: { Goal_amount?: string; Collected: string }[];
  dotunRadius: number;
  dotunStrokeWidth: number;
}) => {
  const number = data.length;
  if (number === 0) {
    return;
  }
  return data.map((el, i) => {
    //I know that here we can remove actions from 100, but it's more understandable
    const percent = el.Goal_amount
      ? (Number.parseInt(el.Collected) / Number.parseInt(el.Goal_amount)) * 100
      : Number.parseInt(el.Collected);

    const angle =
      percent >= 100 ? (3 * Math.PI) / 2 : ((3 * Math.PI) / 200) * percent;

    const r =
      number === 1
        ? dotunRadius
        : dotunRadius + 12 - 2 * i - (i * dotunStrokeWidth) / number;
    return (
      <React.Fragment key={i}>
        <Arc
          strokeWidth={dotunStrokeWidth / number}
          r={r}
          color={colors[i]}
          angle={angle}
          filter={colors[i] === "#ffa800" ? "url(#glow)" : undefined}
        />
        {colors[i] === "#ffa800" && (
          <Glow r={r} strokeWidth={dotunStrokeWidth / number} angle={angle} />
        )}
      </React.Fragment>
    );
  });
};
