import { z } from "zod";

export const getValSchema = (pas: string) => {
  return z.object({
    password: z
      .literal(pas, {
        errorMap: () => ({ message: "it`s not the same password" }),
      }) //a small step towards xss protection
      .refine((value) => !value.includes("<") && !value.includes(">"), {
        message: "The password contains prohibited characters",
      }),
  });
};
