import React from "react";

const ProgressBar = ({
  completed,
  firstLabel,
  secondLabel,
}: { completed: string; firstLabel: string; secondLabel: string }) => {
  return (
    <div
      className="w-full px-3 h-10 flex justify-between items-center !rounded-xl"
      style={{
        background: `linear-gradient(
          to right,
          #fea800 ${completed},
          #523d12 ${completed}
        )`,
      }}
    >
      <p className="inline-block">{firstLabel}</p>
      <p className="inline-block">{secondLabel}</p>
    </div>
  );
};

export default ProgressBar;
