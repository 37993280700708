import { useMutation } from "@tanstack/react-query";
import { AuthAPI } from "services/API/Auth";
import useStore from "store";
import smalltalk from "utils/libraries/smalltalk";

const useLogin = (registration?: boolean | string) => {
  const { setLoggedIn, email, password } = useStore((store) => store);
  return useMutation({
    mutationKey: ["login"],
    mutationFn: async () => await AuthAPI.login({ email, password }),
    onSuccess: (res) => {
      localStorage.setItem("token", res.data.access);
      setLoggedIn(true);
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage("Logged in");
      }
    },
    onError: (e) => {
      if (!registration) {
        smalltalk.alert('Error',e.response.data.detail);
      }
    },
  });
};

export default useLogin;
