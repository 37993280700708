import { useState, useEffect, useRef, type RefObject } from "react";
import { useQuery } from "@tanstack/react-query";
import { PeriodicSpendsAPI } from "services/API/PeriodicSpends";
import { useSetCoolScroll } from "utils/hooks/useSetCoolScroll";
import { sanitizeObject } from "utils/functions/sanitizeObject";
import DOMPurify from "dompurify";
import PeriodicSpendsWindow from "./PeriodicSpends";
import useStore from "store";

interface PeriodicSpendsWindowProps {
  featureRef: RefObject<HTMLElement>;
  onBack: () => void;
}

const PeriodicSpendsContainer = ({
  featureRef,
  onBack,
}: PeriodicSpendsWindowProps) => {
  const { data, isPending } = useQuery({
    queryKey: ["get periodic spends"],
    queryFn: () => PeriodicSpendsAPI.getSpends().then((res) => res.data),
  });

  const [creatingPayment, setCreatingPayment] = useState<boolean>(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentRef = useRef<HTMLDivElement>(null);
  const resizeRef = useRef<HTMLDivElement>(null);

  const [windowSize, setWindowSize] = useState(() => {
    const savedSize = localStorage.getItem("panelSize");
    if (savedSize) {
      const { width, height } = JSON.parse(DOMPurify.sanitize(savedSize));
      return { width, height };
    }
    return { width: 432, height: 750 };
  });

  useSetCoolScroll(featureRef);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        setScrollPosition(contentRef.current.scrollTop);
      }
    };

    if (contentRef.current) {
      contentRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (contentRef.current) {
        contentRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      if (resizeRef.current && featureRef.current) {
        const rect = featureRef.current.getBoundingClientRect();
        const aspectRatio = rect.width / rect.height;

        let newHeight = Math.max(750, Math.min(e.clientY - rect.top, 870));
        let newWidth = newHeight * aspectRatio;

        if (newWidth > 510) {
          newWidth = 510;
          newHeight = newWidth / aspectRatio;
        }

        setWindowSize({ width: newWidth, height: newHeight });
        localStorage.setItem(
          "panelSize",
          JSON.stringify(
            sanitizeObject({ width: newWidth, height: newHeight }),
          ),
        );
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
      document.body.style.userSelect = "";
    };

    const handleMouseDown = (e: MouseEvent) => {
      e.preventDefault();
      document.body.style.userSelect = "none";

      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    };

    if (resizeRef.current) {
      resizeRef.current.addEventListener("mousedown", handleMouseDown);
    }

    return () => {
      if (resizeRef.current) {
        resizeRef.current.removeEventListener("mousedown", handleMouseDown);
      }
      document.body.style.userSelect = "";
    };
  }, [featureRef]);

  const {
    userRestrictions: { periodicSpends: limit },
  } = useStore();
  const isLimitReached = data?.length >= limit;

  return (
    <PeriodicSpendsWindow
      isLimitReached={isLimitReached}
      featureRef={featureRef}
      contentRef={contentRef}
      onBack={onBack}
      windowSize={windowSize}
      isPending={isPending}
      resizeRef={resizeRef}
      creatingPayment={creatingPayment}
      setCreatingPayment={setCreatingPayment}
      data={data}
      limit={limit}
    />
  );
};

export default PeriodicSpendsContainer;
