import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { MonthsAPI } from "services/API/Months";
import showErrorMessage from "utils/constants/showErrorMessage";
import Months from "./Months";
import useStore from "store/store";
import DOMPurify from "dompurify";

const MonthsContainer = () => {
  const [currentMonthNum, setCurrentMonthNum] = useState(
    new Date().getMonth() + 1
  );
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const currentMonth = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(new Date().setMonth(currentMonthNum - 1));

  const { setAccounts, setTotalBalance, setCategories } = useStore();

  const { mutate, mutateAsync, data, isPending } = useMutation({
    mutationKey: ["get backup list"],
    mutationFn: MonthsAPI.getBackupList,
    onSuccess: (data) => {
      setAccounts(data.data.accounts);
      const totalBalance = data.data.total_balance;
      setTotalBalance([
        {
          balance: totalBalance.balance,
          formatted_balance: totalBalance.balance_converted,
          currency: JSON.parse(
            DOMPurify.sanitize(localStorage.getItem("space"))
          ).currency,
        },
      ]);
      setCategories(data.data.categories);
    },
    onError: showErrorMessage,
  });

  return (
    <Months
      currentYear={currentYear}
      currentMonth={currentMonth}
      currentMonthNum={currentMonthNum}
      setCurrentYear={setCurrentYear}
      setCurrentMonthNum={setCurrentMonthNum}
      mutate={mutate}
      isLoading={isPending}
      mutateAsync={mutateAsync}
    />
  );
};

export default MonthsContainer;
