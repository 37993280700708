import type { Dispatch, SetStateAction } from "react";
import { type SubmitHandler, useForm } from "react-hook-form";
import { valSchema } from "utils/validators/Account/CreateAccount";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation } from "@tanstack/react-query";
import { AccountAPI } from "services/API/Account";
import type { ICreateAccountReq } from "utils/types/request/AccountRequest";
import { currencies } from "utils/constants/currencies";
import { queryClient } from "main";
import CreateAccount from "./CreateAccount";
import showErrorMessage from "utils/constants/showErrorMessage";

export interface IFormInputs {
  includeTotalBal: boolean;
  title: string;
  currency: string;
  balance: string;
}

const CreateAccountContainer: React.FC<{
  setModal: Dispatch<SetStateAction<boolean>>;
}> = ({ setModal }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: zodResolver(valSchema),
  });
  const { mutateAsync, isPending } = useMutation({
    mutationKey: ["create account"],
    mutationFn: (data: ICreateAccountReq) => AccountAPI.CreateAccount(data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["get accounts"] });
      setModal(false);
    },
    onError: showErrorMessage,
  });
  const onSubmit: SubmitHandler<IFormInputs> = ({
    title,
    balance,
    currency,
    includeTotalBal,
  }) => {
    mutateAsync({
      title,
      balance: balance === "" ? 0 : Number.parseFloat(balance),
      currency: currencies[currency],
      include_in_total_balance: includeTotalBal,
    });
  };
  return (
    <CreateAccount
      setModal={setModal}
      setValue={setValue}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      errors={errors}
      isLoading={isPending}
    />
  );
};

export default CreateAccountContainer;
