import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import type { ReactNode } from "react";

const stripePromise = loadStripe(
  "pk_live_51OaEz8J4gLcb8EJ9itysEpsC4GvCGDycOg2MQHmCn93iZVAfsP3rQ9bqzTqYiDeVsVUUMVjbWV1PY1pIIUnXxcp200k2QBISlL"
);
const StripeWrapper = ({
  clientSecret,
  children,
  customer,
  ephemeralKey,
}: {
  clientSecret: string;
  children: ReactNode;
  customer: string;
  ephemeralKey: string;
}) => {
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret,
        appearance: {
          theme: "night",
        },
        customerOptions: {
          customer,
          ephemeralKey,
        },
      }}
    >
      {children}
    </Elements>
  );
};

export default StripeWrapper;
